import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from './store/index';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { fetchSounds } from './services';

fetchSounds();

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD5FiLreOhOD7Su00AgoJQgAaQS0s0J1xg',
  authDomain: 'visitadmin1-1172.firebaseapp.com',
  databaseURL: 'https://visitadmin1-1172.firebaseio.com',
  projectId: 'visitadmin1-1172',
  storageBucket: 'visitadmin1-1172.appspot.com',
  messagingSenderId: '587654000125',
  appId: '1:587654000125:web:0bc7950f93a0da0952fd94',
  measurementId: 'G-5SLFN1PNM9',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
