import { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.querySelector('#video-root');

export default class VideoModal extends Component {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
    this.el.classList.add('modal-container');
  }

  componentDidMount = () => {
    modalRoot.appendChild(this.el);
  };

  componentWillUnmount = () => {
    modalRoot.removeChild(this.el);
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}
