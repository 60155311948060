import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { connect } from 'react-redux';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {
  getTransferList,
  adminConsole,
  addNewCase,
  getDivisionList,
} from '../services';
import { fetchConsults } from '../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class AddNewCaseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      name: '',
      phone: '',
      proposalNo: '',
      branchCode: '',
      scheduleDate: null,
      doctorsList: [],
      selectedDoctorIndex: '',
      divisionList: [],
      selectedDivision: '',
      isDirect: false,
    };
  }

  componentDidMount() {
    this.getDivisionList();
    adminConsole()
      .then((data) => {
        this.setState({ doctorsList: data.activeConsults });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDivisionList = () => {
    getDivisionList()
      .then((res) => {
        this.setState({
          divisionList: res.result || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchConsults = () => {
    const { dispatch, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: 'Video Call cases', isSelected: true }],
      }),
    );
  };
  setName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        name: value,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  };

  setPhone = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        phone: value,
      });
    } else {
      this.setState({
        phone: '',
      });
    }
  };

  onScheduleDateChanged = (date) => {
    this.setState({
      scheduleDate: date,
    });
  };

  onDoctorSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDoctorIndex: val.value,
      });
    }
  };
  onDivisionSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDivision: val.value,
      });
    }
  };
  setPhoneNumber = (e) => {
    console.log(e.target.value);
    const re = /^[0-9\b]+$/;
    if (!e.target.value) {
      this.setState({ phone: '' });
    }
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ phone: e.target.value });
    }
  };

  setProposalNo = (e) => {
    console.log(e.target.value);
    const re = /^[0-9\b]+$/;
    if (!e.target.value) {
      this.setState({ proposalNo: '' });
    }
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ proposalNo: e.target.value });
    }
  };

  setBranchCode = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        branchCode: value,
      });
    } else {
      this.setState({
        branchCode: '',
      });
    }
  };

  addCase = () => {
    const {
      name,
      phone,
      proposalNo,
      selectedDoctorIndex,
      branchCode,
      selectedDivision,
      isDirect,
    } = this.state;
    let { scheduleDate } = this.state;
    let newScheduleDate = new Date(scheduleDate);
    newScheduleDate = new Date(
      newScheduleDate.setHours(newScheduleDate.getHours() + 5),
    );
    newScheduleDate = newScheduleDate.setMinutes(
      newScheduleDate.getMinutes() + 30,
    );
    this.setState({
      loading: true,
      showAlert: false,
    });
    if (!scheduleDate) {
      newScheduleDate = null;
    } else {
      newScheduleDate = new Date(newScheduleDate);
    }
    addNewCase(
      name,
      phone,
      proposalNo,
      branchCode,
      newScheduleDate,
      selectedDoctorIndex,
      selectedDivision,
      isDirect,
      this.props.user.doctorId,
    )
      .then((res) => {
        if (res.message === 'warning') {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }
        this.setState({
          alertText: 'new case created successfully',
          alertType: 'success',
          showAlert: true,
          name: '',
          phone: '',
          proposalNo: '',
          selectedDoctorIndex: '',
          scheduleDate: null,
        });
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            err.response.errorMessage ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
        this.fetchConsults();
      });
  };

  render() {
    const {
      alertText,
      alertType,
      branchCode,
      name,
      showAlert,
      loading,
      phone,
      scheduleDate,
      doctorsList,
      proposalNo,
      selectedDoctorIndex,
      divisionList,
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add a new case</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                            ? 'alert-warning'
                            : 'alert-danger'
                        }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="doctor-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Propsal No</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-name-input"
                          value={proposalNo}
                          onChange={this.setProposalNo}
                        />
                      </div>
                      <label
                        htmlFor="branch-code-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Branch Code</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="branch-code-input"
                          value={branchCode}
                          onChange={this.setBranchCode}
                        />
                      </div>
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={name}
                          onChange={this.setName}
                        />
                      </div>
                      <label
                        htmlFor="doctor-phone-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Phone</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-phone-input"
                          value={phone}
                          onChange={this.setPhoneNumber}
                        />
                      </div>

                      <label
                        htmlFor="doctor-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Assign Doctor</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <Select
                          options={doctorsList.map((ele) => ({
                            value: ele.doctorId,
                            label: `${ele.doctorName}(${ele.count} active consults)`,
                          }))}
                          onChange={this.onDoctorSelected}
                          isDisabled={this.state.loading}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <label
                        htmlFor="doctor-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Schedule Video Call</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <DatePicker
                          selected={scheduleDate}
                          onChange={this.onScheduleDateChanged}
                          showTimeSelect
                          timeFormat="hh:mm a"
                          timeIntervals={15}
                          minDate={new Date()}
                          dateFormat="yyyy-MM-dd HH:mm"
                          className="newCaseInput"
                        />
                      </div>
                      <label
                        htmlFor="doctor-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Select division</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <Select
                          options={divisionList.map((ele) => ({
                            value: ele.value,
                            label: `${ele.label}`,
                          }))}
                          onChange={this.onDivisionSelected}
                          isDisabled={this.state.loading}
                          classNamePrefix="react-select"
                        />
                      </div>
                      <label
                        htmlFor="doctor-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">IS DIRECT</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="checkbox"
                          onChange={() => {
                            this.setState({
                              isDirect:
                                this.state.isDirect === true ? false : true,
                            });
                          }}
                          checked={this.state.isDirect ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <PrimaryButton
                      loading={loading}
                      onTap={this.addCase}
                      disabled={!phone || !proposalNo || !name}
                    >
                      <BoldText fontSize="16px" color="white">
                        Add New Case
                      </BoldText>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(AddNewCaseModal);
