import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import VideoModal from '../../containers/common/VideoModal';
import StyledTappable from './StyledTappable';

import { FaTimes } from 'react-icons/fa';
import 'react-resizable/css/styles.css';

const CloseContainer = styled(StyledTappable)`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  z-index: 10;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const PipContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 2px;
`;
function VideoPlayer({ url, handleDraggablePlayerControl, data, onError }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    video.addEventListener('play', (event) =>
      handleDraggablePlayerControl('start', data, event),
    );
    video.addEventListener('pause', (event) =>
      handleDraggablePlayerControl('paused', data, event),
    );
    video.addEventListener('ended', (event) =>
      handleDraggablePlayerControl('ended', data, event),
    );
    video.addEventListener('error', (event) => {
      console.error('Error occurred: ', event);
      onError(JSON.stringify(event));
    });

    return () => {
      video.removeEventListener('play', handleDraggablePlayerControl);
      video.removeEventListener('pause', handleDraggablePlayerControl);
      video.removeEventListener('ended', handleDraggablePlayerControl);
    };
  }, []);
  return data.type === 'voice' ? (
    <audio
      ref={videoRef}
      src={url}
      width={'100%'}
      height={'100%'}
      controls
      autoPlay
    >
      <p>
        If you are reading this, it is because your browser does not support the
        audio element.{' '}
      </p>
    </audio>
  ) : (
    <video
      ref={videoRef}
      src={url}
      width={'100%'}
      height={'100%'}
      controls
      autoPlay
    />
  );
}
function DraggablePlayer({
  url,
  type,
  hideModal,
  handleDraggablePlayerControl,
  info,
}) {
  const [error, setError] = useState('');
  return (
    <VideoModal>
      <Draggable>
        <PipContainer>
          <CloseContainer onTap={hideModal}>
            <CrossIcon />
          </CloseContainer>
          <ResizableBox
            width={400}
            height={type === 'video' ? 300 : 100}
            minConstraints={[200, 150]}
            maxConstraints={[1800, 900]}
          >
            <VideoPlayer
              url={url}
              handleDraggablePlayerControl={handleDraggablePlayerControl}
              data={{ ...info }}
              onError={setError}
            />
          </ResizableBox>
          <p>Error: {error}</p>
        </PipContainer>
      </Draggable>
    </VideoModal>
  );
}

export default DraggablePlayer;
