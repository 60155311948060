import React, { Component } from 'react';
import styled from 'styled-components';
import {
  FaExclamationTriangle,
  FaCheckSquare,
  FaExclamationCircle,
} from 'react-icons/fa';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import { importCases } from '../services';
import { fetchConsults } from '../actions';
import { connect } from 'react-redux';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class ImportCasesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      uploadButtonDisabled: true,
      apiResponses: [],
      showAlert: false,
      alertText: '',
      alertType: 'success',
      disabledButton: false,
    };
  }

  getUrl = () => {
    switch (this.props.insurer) {
      case 'aegon':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/aegon-bulk.xlsx';
      case 'lic':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/lic-bulk.xlsx';
      case 'ipru':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/ipru-bulk_sample_2.2.xlsx';
      case 'absli':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/absli-bulk.xlsx';
      case 'absli-vv':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/VV+Template.xlsx';
      case 'max':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/niva-bulk.xlsx';
      case 'mchi':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/mchi-import-format+(9).xlsx';
      case 'reliance':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/reliance-upload-updated-format.xlsx';
      case 'godigit':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/reliance-upload-format.xlsx';
      case 'hdfc':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/hdfc_import+(1).xlsx';
      default:
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/abhi-import.xlsx';
    }
  };

  openSampleFile = () => {
    const anchor = document.createElement('a');

    anchor.setAttribute('href', this.getUrl());
    anchor.setAttribute('target', '_blank');
    anchor.click();
  };

  updateUploadButton = () => {
    const file = document.querySelector('#upload-files-input').files[0];
    if (file) {
      this.setState({
        uploadButtonDisabled: false,
      });
    } else {
      this.setState({
        uploadButtonDisabled: true,
      });
    }
  };

  fetchConsults() {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: activeFilterName, isSelected: true }],
      }),
    );
  }

  uploadCases = () => {
    this.setState({
      disabledButton: true,
    });
    const file = document.querySelector('#upload-files-input').files[0];
    const onComplete = () => {
      const { apiResponses } = this.state;
      if (apiResponses[apiResponses.length - 1].message === 'failure') {
        return;
      }
      this.setState({
        showAlert: true,
        disabledButton: true,
        alertText: 'Import successful',
        alertType: 'success',
      });
      this.fetchConsults();
    };
    const onError = (errorMsg) => {
      console.log(errorMsg);
      this.setState({
        showAlert: true,
        alertText: 'Something went wrong. Please try again later',
        alertType: 'danger',
      });
    };
    const onSuccess = (data) => {
      const { apiResponses } = this.state;
      const updatedApiReponses = Array.from(apiResponses);
      updatedApiReponses.push(data);
      this.setState({
        apiResponses: updatedApiReponses,
      });
    };
    importCases(file, onSuccess, onError, onComplete);
  };

  render() {
    const {
      alertText,
      alertType,
      apiResponses,
      showAlert,
      loading,
      uploadButtonDisabled,
      disabledButton,
    } = this.state;
    const renderProgress = (ele, idx, arr) => {
      if (ele.message === 'success') {
        if (idx === arr.length - 1 && ele.totalSteps !== ele.completedSteps) {
          return (
            <li className="list-group-item">
              <span className="spinner-border float-left" role="status">
                <span className="sr-only">Loading...</span>
              </span>
              <Text fontSize="14px">{ele.status}</Text>
            </li>
          );
        } else {
          return (
            <li className="list-group-item">
              <FaCheckSquare className="text-success float-left" />
              <Text fontSize="14px">{ele.status}</Text>
            </li>
          );
        }
      } else if (ele.message === 'warning') {
        return (
          <li className="list-group-item">
            <FaExclamationTriangle className="text-warning float-left" />
            <Text fontSize="14px">{ele.errorMessage}</Text>
          </li>
        );
      } else if (ele.message === 'failure') {
        return (
          <li className="list-group-item">
            <FaExclamationCircle className="text-danger float-left" />
            <Text fontSize="14px">{ele.errorMessage}</Text>
          </li>
        );
      } else {
        return false;
      }
    };
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                Import {this.props.insurer.toUpperCase()} Cases
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${
                        alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                      }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <button
                          className="btn btn-link"
                          onClick={this.openSampleFile}
                        >
                          <Text fontSize="14px">View sample file</Text>
                        </button>
                      </div>
                    </div>
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="upload-files-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Choose your XLSX file</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="file"
                          className="form-control"
                          id="upload-files-input"
                          onChange={this.updateUploadButton}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <PrimaryButton
                      loading={loading}
                      onTap={this.uploadCases}
                      disabled={uploadButtonDisabled || disabledButton}
                    >
                      <BoldText fontSize="16px" color="white">
                        Upload
                      </BoldText>
                    </PrimaryButton>
                  </div>
                  <div className="col-12 mt-3">
                    <ul className="list-group list-group-flush">
                      {apiResponses.map(renderProgress)}
                    </ul>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeFilterName: state.info.activeFilterName,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(ImportCasesModal);
