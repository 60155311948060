import axios from 'axios';
import jsonpipe from 'jsonpipe';
import config from '../config';
import { store } from '../store';
import { showError } from '../utils';
import { getBaseUrlForInsurer } from './utils';

window.audioContext = new window.AudioContext();

export function fetchSounds() {
  const newCaseUrl = '/new_case.m4a';
  const newMsgUrl = '/new_message.m4a';
  const onlineUrl = '/online.m4a';
  const offlineUrl = '/offline.m4a';
  const followupUrl = '/followup.m4a';
  axios
    .get(newMsgUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.newMsgAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
  axios
    .get(newCaseUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.newCaseAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
  axios
    .get(onlineUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.onlineAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
  axios
    .get(offlineUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.offlineAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
  axios
    .get(followupUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.followupAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function fetchConsults(
  authToken,
  filters,
  selectedSponsorId,
  searchText,
  consultationId,
  doctorId,
  applicationIds,
) {
  const { user } = store.getState();
  const headers = {
    authorization: authToken,
  };

  const baseUrl = `${config.apiBaseUrl}/consultations/all`;

  let params = {};

  if (searchText) {
    params['name'] = searchText;
    if (user.qc) {
      params['closed'] = true;
      params['qcUnapproved'] = true;
    }
  }

  if (selectedSponsorId) {
    params['sponsorId'] = selectedSponsorId;
  }
  if (consultationId) {
    params['consultationId'] = consultationId;
  }
  let ignoreAllFilters = false;
  if (filters) {
    filters.forEach((filter) => {
      console.log(filter);
      if (filter.isSelected) {
        switch (filter.name) {
          case 'My Cases': {
            params['onlyMe'] = true;
            break;
          }
          case 'Open': {
            params['open'] = true;
            break;
          }
          case 'Completed': {
            params['closed'] = true;
            break;
          }
          case 'My Completed Cases': {
            params['closed'] = true;
            params['onlyMe'] = true;
            break;
          }
          case 'My Open Cases': {
            params['open'] = true;
            params['onlyMe'] = true;
            break;
          }
          case 'Followups': {
            params['followup'] = true;
            break;
          }
          case 'Unattempted': {
            params['unattempted'] = true;
            break;
          }
          case 'Non workable': {
            params['nonWorkable'] = true;
            break;
          }
          case 'All': {
            ignoreAllFilters = true;
            break;
          }
          case 'User rejected': {
            params['userRejected'] = true;
            if (user.qc) {
              params['closed'] = true;
              params['qcUnapproved'] = true;
            }
            break;
          }
          case 'VideoCall cases': {
            params['callType'] = 'video';
            break;
          }
          case 'Voice call cases': {
            params['callType'] = 'voice';
            break;
          }
          case 'showAllForAdmin': {
            params['showAllForAdmin'] = true;
            break;
          }
          case 'Policy Bazaar': {
            params['source'] = 'PB';
            break;
          }
          case 'PIVC cases': {
            params['pivc'] = true;
            break;
          }
          case 'PIVV cases': {
            params['pivv'] = true;
            break;
          }
          case 'bulkCase': {
            params['bulk'] = true;
            break;
          }
          case 'High Priority Case': {
            params['highPriorityCase'] = true;
            break;
          }
          case 'Pending Case With Recording': {
            params['highPriorityCase'] = true;
            break;
          }
          case 'Banca Case': {
            params['bancaCase'] = true;
            break;
          }
          case 'Instant Tele': {
            params['instantTele'] = true;
            break;
          }
          case 'Instant Tele Missed Case': {
            params['instantTeleMissedCase'] = true;
            break;
          }
          case 'Duplicate Case': {
            params['duplicateCase'] = true;
            break;
          }
          case 'Paytm Case': {
            params['paytmCase'] = true;
            params['open'] = true;
            break;
          }
          case 'Paytm Unattempted Case': {
            params['paytmCase'] = true;
            params['open'] = true;
            params['unattempted'] = true;
            break;
          }
          case 'Dailer Answered': {
            params['dailerAnswered'] = true;
            break;
          }
          case 'Dailer Unanswered': {
            params['dailerUnanswered'] = true;
            break;
          }
          case 'qcUnapproved': {
            params['closed'] = true;
            params['qcUnapproved'] = true;
            break;
          }
          case 'TU INCOMPLETE': {
            params['tuIncomplete'] = true;
            break;
          }
          case 'division': {
            params['division'] = filter.value;
            break;
          }
          case 'qcPendingSince': {
            params['qcPendingSince'] = filter.value;
            break;
          }
          case 'pendingSince': {
            params['pendingSince'] = filter.value;
            break;
          }
          case 'vendorCase': {
            params['vendorId'] = filter.value;
            break;
          }
          case 'discrepencyCase': {
            params['discrepency'] = true; //filter.value;
            break;
          }
          case 'Discrepency Case': {
            params['discrepency'] = true; // filter.value;
            break;
          }
          case 'Grievance Case': {
            params['grievance'] = true; // filter.value;
            break;
          }
          case 'ON-HOLD': {
            params['onhold'] = true;
          }
          case 'Joint MER': {
            params['discrepency'] = true; // filter.value;
            break;
          }
          case 'QC TAT Surpass': {
            params['qcTatSurpass'] = true;
            break;
          }
          case 'ZOOPER': {
            params['zooper'] = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    // if all filter selected ignore rest all filters
    if (ignoreAllFilters) {
      params = {};
    }
    if (user.qc && !params['userRejected']) {
      if (params['bancaCase']) {
        params = {
          closed: true,
          qcUnapproved: true,
          bancaCase: true,
        };
      } else if (params['source']) {
        params = {
          closed: true,
          qcUnapproved: true,
          source: 'PB',
        };
      } else if (params['qcPendingSince'] >= 0) {
        params = {
          closed: true,
          qcUnapproved: true,
          qcPendingSince: params['qcPendingSince'],
        };
      } else if (params['duplicateCase']) {
        params = {
          closed: true,
          qcUnapproved: true,
          duplicateCase: true,
        };
      } else if (params['instantTele']) {
        params = {
          closed: true,
          qcUnapproved: true,
          instantTele: true,
        };
      } else if (params['qcTatSurpass']) {
        params = {
          closed: true,
          qcUnapproved: true,
          qcTatSurpass: true,
        };
      } else if (params['onhold']) {
        params = {
          closed: true,
          qcUnapproved: true,
          onhold: true,
        };
      } else {
        params = {
          closed: true,
          qcUnapproved: true,
        };
      }
    }
    if (!user.qc && !user.admin) {
      params['onlyMe'] = true;
    }
    if (user.qc && !user.admin) {
      params['assignedToDoctorId'] = user.doctorId;
    }
    console.log(params);
  }

  if (doctorId !== -1) {
    params['assignedToDoctorId'] = doctorId;
  }
  if (user.armsoft) {
    params['armsoft'] = user.armsoft;
  }
  if (user.vendorId) {
    params['vendorId'] = user.vendorId;
  }
  console.log({ baseUrl, headers, params }, 'baseUrl for fetchConsults');
  let body = { applicationIds };
  if (params['name']) {
    body['name'] = params['name'];
  }
  return axios
    .post(baseUrl, body, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        console.log(responseBody);
        return { responseBody, responseURL: res.request.responseURL };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchConsultsOnPagination(
  authToken,
  page,
  url,
  applicationIds,
) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers, page, url }, 'fetchConsultsOnPagination');

  return axios
    .post(
      url,
      {
        applicationIds,
      },
      {
        headers,
        params: {
          page,
        },
      },
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function recommendSpecialist(
  authToken,
  userId,
  vertical,
  type,
  consultationId,
) {
  const body = { userId, vertical, type, consultationId };

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/opd/suggest`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadImageAttachment(
  file,
  consultationId,
  onUploadProgress,
  authToken,
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post(
      `${config.apiBaseUrl}/consultations/upload/file/${consultationId}`,
      formData,
      {
        headers,
        onUploadProgress,
      },
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function videoCallUser(authToken, doctorId, consultationId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/video/callPatient/${doctorId}?consultationId=${consultationId}`,
      {},
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.callInfo;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function stopVideoCall(authToken, doctorId, sessionId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/video/endCall/${doctorId}?sessionId=${sessionId}`,
      {},
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function closeConsultation(authToken, consultationId, userId, reason) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/consultations/close`,
      {
        consultationId,
        userId,
        reason,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message.toLowerCase() === 'success') {
        console.log(responseBody.medicalJson);
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function voiceCallUser(authToken, consultationId, userId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/click-to-call/initiate`,
      {
        consultationId,
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchDiseaseSuggestions(query, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/diseases/search`, {
      headers,
      params: {
        query,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getQuestionForDisease(
  diseaseId,
  userId,
  relativeId,
  authToken,
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/diseases/questions/${diseaseId}/user/${userId}/${relativeId}`,
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitAnswerForDisease(
  diseaseId,
  userId,
  relativeId,
  answers,
  authToken,
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/diseases/record`,
      {
        userId,
        diseaseId,
        relativeId,
        answers,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeDisease(diseaseId, relativeId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/diseases/remove`,
      {
        relativeId,
        diseaseId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getStandardMessages(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/standard-messages`, {
      headers,
      params: {
        userId,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getUserNotes(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/user-notes`, {
      params: {
        userId,
      },
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function addUserNote(userId, comment) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/consultations/user-notes`,
      {
        userId,
        comment,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getFormQuestions(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/question-form`;
  return axios
    .get(url, {
      params: {
        userId,
      },
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getFormSubQuestions(userId, questionId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/get-sub-question-answers`;
  return axios
    .get(url, {
      params: {
        userId,
        questionId,
      },
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function saveAnswersForRelative(
  userId,
  relativeId,
  questionId,
  formAnswers,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/save-relative-answer`;
  return axios
    .post(
      url,
      {
        userId,
        relativeId,
        questionId,
        formAnswers,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteAnswerForRelative(userId, relativeId, questionId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/remove-relative-answer`;
  return axios
    .post(
      url,
      {
        userId,
        relativeId,
        questionId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function markFormComplete(
  userId,
  remarks,
  followUp,
  policyAppliedType,
  advisorNo,
  formSubmitted,
  formSubmittedRemarks,
  adversityTag,
  adversityRemarks,
  qcRemarks,
  qcError,
  discrepancyCallRemark,
  qcApproved,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-user-status`;
  return axios
    .post(
      url,
      {
        userId,
        closed: true,
        remarks,
        followUp,
        policyAppliedType,
        advisorNo,
        formSubmitted,
        formSubmittedRemarks,
        adversityTag,
        adversityRemarks,
        qcRemarks,
        qcError,
        qcApproved,
        discrepancyCallRemark,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markRuleEngineFormComplete(userId, qc) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-user-status-rule-engine`;
  return axios
    .post(
      url,
      {
        userId,
        qc,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markFormApproved(userId, remarks, submitBrute) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-user-status`;
  return axios
    .post(
      url,
      {
        userId,
        qcApproved: true,
        remarks,
        submitBrute,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
    });
}

export function previewForm(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/preview`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function initiateFormMerCall(userId, isAlternate) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/initiate-call`;
  return axios
    .post(
      url,
      {
        userId,
        isAlternate,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markFollowupUser(userId, followup) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-user-status`;
  return axios
    .post(
      url,
      {
        userId,
        followup,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchCallLogs(userId, callType) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
    'XSRF-TOKEN': 'a9e82f49-7c3a-4c1d-bb44-3de45a6e789b',
  };

  const url = `${getBaseUrlForInsurer()}/call-logs`;
  return axios
    .get(url, {
      headers,
      params: {
        userId,
        callType: callType,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function saveRemarksForCall(ucid, remarkId, remarks, callType, userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body =
    callType === 'video'
      ? {
        remarks,
        remarkId,
        ucid: null,
        callId: ucid,
        callType,
      }
      : {
        remarks,
        remarkId,
        ucid,
        userId,
      };

  const url = `${getBaseUrlForInsurer()}/call-logs/remarks`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadSignature(file, doctorId, onUploadProgress) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post(
      `${config.apiBaseUrl}/consultations/upload-signature/${doctorId}`,
      formData,
      {
        headers,
        onUploadProgress,
      },
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCasesForRemark(remarkId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/max-cases-remarks`, {
      headers,
      params: {
        remarkId,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      // showError(err);
      throw err;
    });
}

export function transferCases(userIds, assignTo) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/transfer-cases`;
  return axios
    .post(
      url,
      {
        userIds,
        assignTo,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function updateQcSelfAssignLimit(qcSelfAssignLimit) {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/admin/self-assign-limit`, {
      qcSelfAssignLimit
    }, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function importCases(file, onSuccess, onError, onComplete) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const formData = new FormData();
  formData.append('file', file);

  const url = `${getBaseUrlForInsurer()}/upload-cases`;
  jsonpipe.flow(url, {
    method: 'POST',
    headers,
    data: formData,
    success: onSuccess,
    error: onError,
    complete: onComplete,
    disableContentType: true,
  });
  }

export function importLICCase(file, onError, onComplete) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const formData = new FormData();
  formData.append('file', file);

  const url = `${getBaseUrlForInsurer()}/upload-cases-csv`;

  axios.post(url, formData, { headers })
      .then((response) => {
        const {  data: { status } } = response;
        onComplete(status);
      })
      .catch((error) => {
        const { status, data: { message, errorMessage } } = error.response;
        onError(errorMessage);
      })
}

export function updatePhone(userId, phone, isAlternate) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-user-phone`;
  return axios
    .post(
      url,
      {
        userId,
        phone,
        isAlternate,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reopenCase(
  userId,
  qc = false,
  repeat = false,
  remark,
  onlyForm = false,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/reopen-case`;
  return axios
    .post(
      url,
      {
        userId,
        qc,
        repeat,
        remark,
        onlyForm,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function rejectCase(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/reject-case`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addNewCase(
  name,
  phone,
  proposalNo,
  branchCode,
  scheduleDate,
  selectedDoctorIndex,
  divisionId,
  isDirect,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-user`;
  return axios
    .post(
      url,
      {
        name,
        phone,
        proposalNo,
        branchCode,
        scheduleTime: scheduleDate,
        doctorId: selectedDoctorIndex,
        divisionId,
        isDirect,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function refetchCallRecording(ucid) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/refetch-call-logs`;
  return axios
    .get(url, {
      headers,
      params: {
        ucid,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendSmsBeforeAttempt(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/sms-before-attempt`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addNewArmsoftCase(
  phoneNo,
  applicationId,
  dob,
  gender,
  name,
  merType,
  source,
  caseTypeLabel,
  countryName,
  countryCode,
  leadId,
  showFormRadio,
  caseType,
  remarks,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-armsoft-user`;
  return axios
    .post(
      url,
      {
        applicationId,
        phoneNo,
        dob,
        gender,
        name,
        merType,
        source,
        caseTypeLabel,
        countryName,
        countryCode,
        leadId,
        showFormRadio,
        caseType,
        remarks,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function convertToArmsoft(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/convert-to-armsoft`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function convertToTele(userId, type) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/convert-to-tele`;
  return axios
    .post(
      url,
      {
        userId,
        type,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDivisionList() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/get-all-divisions`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getUserMembers(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/get-user-members?userId=${userId}`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateMemberInfo(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/update-relative-info`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getMemberFormUrl(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/get-form-url?userId=${userId}`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendTranscript(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/send-transcript`;
  return axios
    .post(
      url,
      { userId },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateDisclosure(userId, disclosure) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-disclosure`;
  return axios
    .post(
      url,
      {
        userId,
        disclosure,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function createNewCase(
  name,
  phoneNumber,
  proposalNo,
  dateOfBirth,
  email,
  gender,
  requirementCode,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-new-user`;
  return axios
    .post(
      url,
      {
        name,
        phoneNumber,
        proposalNo,
        dateOfBirth,
        email,
        merType: 'video',
        gender,
        requirementCode,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function createCase(body) {
  console.log(body);
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-new-user`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateTranscript(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/update-transcript`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function videoScreenshotByConsultationId(
  formData,
  consultationId,
  type,
  onUploadProgress,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/video-screenshot-manual?consultationId=${consultationId}&type=${type}`;
  return axios
    .post(url, formData, {
      headers,
      onUploadProgress,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function autoDailer() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/auto-dailer`;
  return axios
    .post(url, {}, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addVideoByConsultationId(
  formData,
  consultationId,
  onUploadProgress,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/add-manual-video?consultationId=${consultationId}`;
  return axios
    .post(url, formData, {
      headers,
      onUploadProgress,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markTuIncomplete(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/mark-tu-incomplete`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function specialNumber(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/special_number`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function resendCallback(consultationId, userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/resend-callback`;
  return axios
    .post(
      url,
      {
        consultationId,
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getVideoDetail(userId, sessionId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/video/refetch-log/${sessionId}`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function setVideo(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${config.apiBaseUrl}/video/session-details`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function allowNightCalling(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/allow-night-calling`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function transferToVendor(userIds, vendorId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/transfer-to-vendor`;
  return axios
    .post(
      url,
      {
        userIds,
        vendorId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markDuplicate(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-duplicate-case-recording`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendUserEmailLink(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations//send-email`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadScreenshotByUserId(
  formData,
  consultationId,
  type,
  onUploadProgress,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/manual-screenshot?userId=${consultationId}&type=${type}`;
  return axios
    .post(url, formData, {
      headers,
      onUploadProgress,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function submitFeedbackAPI(
  userId,
  insurer,
  doctorId,
  feedback,
  dropOpt,
  npsDropOpt,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/submit-feedback`;
  return axios
    .post(
      url,
      { userId, insurer, doctorId, feedback, dropOpt, npsDropOpt },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function getFeedbackBy(userId, insurer) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/get-feedback-by`;
  return axios
    .post(
      url,
      { userId, insurer },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data.completed_by;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function fetchFeedbacks() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/fetch-feedback`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function addInDailyReport(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-in-report`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAudioByUserId(formData, userId, onUploadProgress) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-manual-audio?userId=${userId}`;
  return axios
    .post(url, formData, {
      headers,
      onUploadProgress,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function createNewNivaCase(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/new-user`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function toggleDiscrepancyService(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/toggle-discrepency`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDiscrepencyService(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/get-discrepency`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchCompleteSheet() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/fetch-complete-sheet`;
  return axios
    .get(url, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchDiscrepencySheet() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/fetch-discrepency-sheet`;
  return axios
    .get(url, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAttemptedSheet() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/fetch-attempted-sheet`;
  return axios
    .get(url, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateCompleteSheet(update_array, application_no) {
  const body = { application_no, update_array };

  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${getBaseUrlForInsurer()}/update-complete-sheet`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateAttemptedSheet(update_array, application_no) {
  const body = { application_no, update_array };

  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${getBaseUrlForInsurer()}/update-attempted-sheet`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateDiscrepencySheet(update_array, application_no) {
  const body = { application_no, update_array };

  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${getBaseUrlForInsurer()}/update-discrepency-sheet`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

//not needed->getFeedbackAck
export async function getFeedbackAck(feedback_id) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/get-feedback-ack-id`;
  return axios
    .post(
      url,
      { feedback_id },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function toggleFeedbackAck(feedback_id, ackVal) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/ack-feedback`;
  return axios
    .post(
      url,
      { feedback_id, ackVal },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function insertFeedbackReplies(id, reply) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/insert-feedback-replies`;
  return axios
    .post(
      url,
      { id, reply },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function getFeedbackRepliesId(id) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/get-feedback-replies-id`;
  return axios
    .post(
      url,
      { id },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function retrieveApplicationId(insurer, user_id) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/consultations/get-app-id`;
  return axios
    .post(
      url,
      { user_id, insurer },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function splitCase(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/split-case`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function caseCompleted(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/case-completed`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getFormValService(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/get-show-form`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function toggleFormService(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/toggle-show-form`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getUserRequirements(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const body = {
    userId: userId,
  };
  const url = `${getBaseUrlForInsurer()}/get-user-requirements`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateUserRequirements(userId, newRequirements) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const body = {
    userId: userId,
    newRequirements: newRequirements,
  };
  const url = `${getBaseUrlForInsurer()}/set-user-requirements`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.status === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function jointMerAegonToggle(body) {
  try {
    const { user } = store.getState();
    const headers = {
      authorization: user.authToken,
    };
    const url = `${getBaseUrlForInsurer()}/toggle-joint-mer`;
    const res = await axios.post(url, body, { headers });
    const responseBody = res.data;

    if (responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    }
  } catch (err) {
    showError(err);
    throw err;
  }
}

export async function updateLanguage(body) {
  try {
    const { user } = store.getState();
    const headers = {
      authorization: user.authToken,
    };
    const url = `${getBaseUrlForInsurer()}/update-language`;
    const res = await axios.post(url, body, { headers });
    const responseBody = res.data;

    if (responseBody.message === 'success') {
      return responseBody;
    }
  } catch (err) {
    showError(err);
    throw err;
  }
}

export async function updateInstantTele(doctorId, instantTele) {
  try {
    const { user } = store.getState();
    const body = {
      doctorId,
      insurer: user.insurer,
      instantTele,
    };
    const headers = {
      authorization: user.authToken,
    };
    const url = `${getBaseUrlForInsurer()}/toggle-instant-tele`;
    const res = await axios.post(url, body, { headers });
    const responseBody = res.data;

    if (responseBody.message === 'success') {
      return responseBody;
    }
  } catch (err) {
    showError(err);
    throw err;
  }
}

export function convertOldForm(userId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/convert-old-form`;
  return axios
    .post(
      url,
      {
        userId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addTranscriptByUserId(formData, userId, onUploadProgress) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/add-manual-transcript?userId=${userId}`;
  return axios
    .post(url, formData, {
      headers,
      onUploadProgress,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function updateAssisted(body) {
  try {
    const { user } = store.getState();
    const headers = {
      authorization: user.authToken,
    };
    const url = `${getBaseUrlForInsurer()}/update-assisted`;
    const res = await axios.post(url, body, { headers });
    const responseBody = res.data;

    if (responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    }
  } catch (err) {
    showError(err);
    throw err;
  }
}

export function markOnHold(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${config.apiBaseUrl}/consultations/mark-on-hold`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function setPlaytimeRemarks(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${config.apiBaseUrl}/admin/set-playtime-remarks`;
  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
