import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import {
  FaCircleNotch,
  FaTimes,
  FaCaretRight,
  FaCaretDown,
} from 'react-icons/fa';
import styled from 'styled-components';

import StyledTappable from '../components/common/StyledTappable';
import { BoldText, Text } from '../components/common/Text';
import {
  getFormQuestions,
  getFormSubQuestions,
  saveAnswersForRelative,
  deleteAnswerForRelative,
  markFormComplete,
  fetchMedicines,
  addNewArmsoftCase,
  getMemberFormUrl,
  getUserRequirements,
  updateUserRequirements,
} from '../services';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

import LoadingComponent from '../components/common/LoadingComponent';
import { PrimaryButton } from '../components/common/Buttons';
import Modal from './common/Modal';
import {
  fetchConsults,
  setRelatives,
  setProposerDetails,
  setWhatsappDeliveryStatus,
  setOneConnectLinks,
  updateConsults,
  resetActiveConsult,
} from '../actions';
import { showError, showSuccess } from '../utils';
import DatePicker from 'react-datepicker';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
`;

const QuestionContainer = styled.div`
  padding: 14px 8px;
  border-bottom: 1px solid;
`;

const SubQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  margin-left: 14px;
`;

const SubAnswerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 968px;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
  max-height: 100%;
`;

const PpmcQuestionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 1.5rem;
  border: 1.2px #8852cc solid;
  border-radius: 1rem;
  padding: 1rem 1.5rem 0 1rem;
  align-items: flex-start;
`;

const TickBox = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #c8ccd4;
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    opacity: ${(props) => (props.checked ? 1 : 0)};
    border-right: 3px solid #8852cc;
    border-bottom: 3px solid #8852cc;
    transform: rotate(45deg);
    top: 0px;
    left: 4px;
  }
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 18px;
`;

const RemoveButton = styled(PrimaryButton)`
  background: white;
  border: 1px solid #8852cc;
  border-color: ${(props) => (props.disabled ? '#B2B2B2' : '#8852cc')};
  color: #8852cc;
`;

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      errorMessage: null,
      isPPMCIpruConsentModalOpen: false,
      notes: [],
      loading: true,
      fetchMessages: false,
      showDetailedQuestionsModal: false,
      modalLoading: true,
      filteredQuestion: [],
      questions: [],
      questionsSelected: new Set(),
      selectedQuestionId: -1,
      detailedQuestions: [],
      expandedRelativeId: -1,
      expandedRelative: null,
      savingAnswers: false,
      caseRemarks: '',
      qcRemarks: '',
      policyAppliedType: '',
      formSubmitted: false,
      formSubmittedRemarks: '',
      advisorNo: '',
      formLink: null,
      marsFilledOptions: [
        { value: 'filled', label: 'FILLED' },
        { value: 'notFilled', label: 'NOT FILLED' },
      ],
      caseTypeOptions: [{ value: 'MCA', label: 'MCA' }],
      nriOptions: [
        { value: 'nri', label: 'NRI' },
        { value: 'notNri', label: 'NOT-NRI' },
      ],
      showConfirmationModal: false,
      lastMedicines: [],
      medicineName: '',
      genericName: '',
      applicationId: '',
      name: '',
      phoneNo: '',
      gender: 'Male',
      dob: '',
      caseTypeLabel: 'PB_VMER',
      maxFormLink: null,
      qcError: '',
      adversityTag: '',
      adversityRemarks: '',
      discrepancyCase: false,
      discrepancyCallType: '',
      discrepancyCallRemark: '',
      confirmOpenForm: false,
      merType: 'video',
      openingForm: false,
      userAdditionalOptions: [],
      defaultRequirementOptions: [],
      viewAnswer: false,
      countryName: '',
      countryCode: '',
      leadId: '',
      showFormRadio: 'show',
      caseType: 'fresh-case',
      remarks: '',
    };
    this.conversationBodyRef = React.createRef();
    this.medicineSelectRef = React.createRef();
  }

  componentDidMount = () => {
    const { activeConsult, user } = this.props;
    // window.addEventListener('keydown', this.handleKeyPress, false);
    if (activeConsult) {
      this.getFormQuestions();
      this.getUserRequirements();
      if (user.insurer === 'ipru') {
        this.setState({
          loading: false,
          caseRemarks: activeConsult.caseRemarks || '',
          policyAppliedType: activeConsult.policyAppliedType || '',
          advisorNo: activeConsult.advisorNo || '',
          qcRemarks: activeConsult.qcRemarks || '',
          adversityTag: activeConsult.adversityTag || '',
          adversityRemarks: activeConsult.adversityRemarks || '',
          discrepancyCase: activeConsult.discrepancyCallRemark ? true : false,
          discrepancyCallType: activeConsult.discrepancyCallType || '',
          discrepancyCallRemark: activeConsult.discrepancyCallRemark || '',
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { activeConsult } = this.props;

    const { questions } = this.state;
    const previousActiveConsult = prevProps.activeConsult;
    if (!activeConsult.consultationId && questions.length > 0) {
      this.setState({
        questions: [],
        questionsSelected: false,
      });
    }
    if (!previousActiveConsult && activeConsult) {
      this.setState({
        caseRemarks: activeConsult.caseRemarks || '',
        policyAppliedType: activeConsult.policyAppliedType || '',
        advisorNo: activeConsult.advisorNo || '',
        formSubmittedRemarks: activeConsult.formSubmittedRemarks || '',
        formSubmitted: activeConsult.formSubmitted,
        qcRemarks: activeConsult.qcRemarks || '',
        adversityTag: activeConsult.adversityTag || '',
        adversityRemarks: activeConsult.adversityRemarks || '',
        discrepancyCase: activeConsult.discrepancyCallRemark ? true : false,
        discrepancyCallType: activeConsult.discrepancyCallType || '',
        discrepancyCallRemark: activeConsult.discrepancyCallRemark || '',
        openingForm: false,
        maxFormLink: null,
        userAdditionalOptions: [],
        defaultRequirementOptions: [],
      });

      this.getFormQuestions();
      this.getUserRequirements();
    } else {
      if (activeConsult) {
        if (previousActiveConsult.user.userId !== activeConsult.user.userId) {
          this.setState({
            caseRemarks: activeConsult.caseRemarks || '',
            policyAppliedType: activeConsult.policyAppliedType || '',
            advisorNo: activeConsult.advisorNo || '',
            formSubmittedRemarks: activeConsult.formSubmittedRemarks || '',
            formSubmitted: activeConsult.formSubmitted,
            qcRemarks: activeConsult.qcRemarks || '',
            adversityTag: activeConsult.adversityTag || '',
            adversityRemarks: activeConsult.adversityRemarks || '',
            confirmOpenForm: false,
            discrepancyCase: activeConsult.discrepancyCallRemark ? true : false,
            discrepancyCallType: activeConsult.discrepancyCallType || '',
            discrepancyCallRemark: activeConsult.discrepancyCallRemark || '',
            openingForm: false,
            maxFormLink: null,
          });
          this.getFormQuestions();
          this.getUserRequirements();
        }
      }
    }
  }

  fetchConsults() {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: activeFilterName, isSelected: true }],
      }),
    );
  }

  getUserRequirements() {
    const { activeConsult, dispatch, user } = this.props;
    const userId = activeConsult.user.userId;
    if (user.insurer !== 'aegon' || !userId) return;
    const requirementCode = [];
    getUserRequirements(userId)
      .then((result) => {
        result.data.map((ele) => {
          if (ele.selected) {
            requirementCode.push({ label: ele.label, value: ele.condition });
          }
        });
        this.setState({
          userAdditionalOptions: result.data,
          defaultRequirementOptions: requirementCode,
        });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  getFormQuestions(backgroundLoad) {
    const { activeConsult, dispatch } = this.props;
    const userId = activeConsult.user.userId;
    const questionsSelected = new Set();
    if (!userId) {
      return this.setState({
        loading: false,
      });
    }
    if (!backgroundLoad) {
      this.setState({ loading: true });
    }
    getFormQuestions(userId)
      .then((res) => {
        if (res.link) {
          this.setState({
            formLink: res.link,
          });
        }
        const questions = res.form;
        const relatives = res.relatives;
        const whatsappDeliveryStatus = res.whatsappDeliveryStatus;
        const proposerDetails = res.proposerInfo;
        const oneConnectLinks = res.oneConnectLinks;
        questions &&
          questions.forEach((ques) => {
            if (ques.questions.some((ele) => ele.answer === 'YES')) {
              questionsSelected.add(ques.mainQuestionId);
            }
          });
        const finalQuestions = [];
        const confidentialQuestions = [];
        questions && questions.forEach(q => {
          if (this.props.insurer === 'ppmc' &&
            proposerDetails && proposerDetails.requirementCode === 'IPRU' &&
            q.text.includes('CONFIDENTIAL REMARK')
          ) {
            confidentialQuestions.push(q)
          } else {
            finalQuestions.push(q)
          }
        });
        this.setState({ questions: finalQuestions || [], filteredQuestion: confidentialQuestions, questionsSelected });
        dispatch(setProposerDetails(proposerDetails));
        dispatch(setRelatives(relatives));
        dispatch(setWhatsappDeliveryStatus(whatsappDeliveryStatus));
        dispatch(setOneConnectLinks(oneConnectLinks));
      })
      .catch((err) => {
        this.setState({
          errorMessage:
            (err.data || {}).errorMessage ||
            'Something went wrong. Please try again later',
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  // handleKeyPress = (event) => {
  //   // console.log({ event, focus: document.activeElement.id });
  //   if (event.code === 'Enter') {
  //     this.sendMessage();
  //   }
  // };

  getFormSubQuestions(questionId) {
    const { activeConsult } = this.props;
    getFormSubQuestions(activeConsult.user.userId, questionId)
      .then((res) => {
        this.setState({
          detailedQuestions: res.answers,
        });
        if (this.props.insurer === 'ppmc') {
          this.setState({
            expandedRelativeId: res.answers[0].relativeId,
            expandedRelative: res.answers[0],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          modalLoading: false,
        });
      });
  }

  chooseAnswer = (questionId, toggleViewAnswer) => () => {
    // conditonally choosing answer
    if (this.props.insurer === 'ppmc') {
      this.setState({
        selectedQuestionId: questionId,
        viewAnswer: toggleViewAnswer,
      });
    } else {
      this.setState({
        showDetailedQuestionsModal: true,
        modalLoading: true,
        selectedQuestionId: questionId,
      });
    }
    this.getFormSubQuestions(questionId);
  };

  selectRelative = (relative) => () => {
    const { savingAnswers } = this.state;
    if (savingAnswers) {
      return;
    }
    this.setState({
      expandedRelativeId: relative.relativeId,
      expandedRelative: relative,
    });
  };

  setAnswer = (subQuestionId, answerType) => (e) => {
    const answer = e.target.value;
    if (answerType === 'year' && !answer.match(/^\d*$/)) {
      return;
    }
    const { detailedQuestions, expandedRelativeId } = this.state;

    // Find the index of the selected relative
    const relativeQuestions = detailedQuestions.find(
      (ele) => ele.relativeId === expandedRelativeId,
    );
    const subQuestion = relativeQuestions.subQuestions.find(
      (ele) => ele.subQuestionId === subQuestionId,
    );
    if (answer) {
      subQuestion.answer = answer;
      subQuestion.unsaved = true;
    } else {
      subQuestion.answer = undefined;
      subQuestion.unsaved = false;
    }
    const updatedDetailedQuestions = Array.from(detailedQuestions);
    this.setState({
      detailedQuestions: updatedDetailedQuestions,
    });
  };

  onAnswerSelected(val, action, subQuestionId) {
    if (action.action === 'select-option') {
      const answer = val.label;
      const { detailedQuestions, expandedRelativeId } = this.state;
      // Find the index of the selected relative
      const relativeQuestions = detailedQuestions.find(
        (ele) => ele.relativeId === expandedRelativeId,
      );
      const subQuestion = relativeQuestions.subQuestions.find(
        (ele) => ele.subQuestionId === subQuestionId,
      );
      if (answer) {
        subQuestion.answer = answer;
        subQuestion.unsaved = true;
      } else {
        subQuestion.answer = undefined;
        subQuestion.unsaved = false;
      }
      const updatedDetailedQuestions = Array.from(detailedQuestions);
      this.setState({
        detailedQuestions: updatedDetailedQuestions,
      });
    }
  }

  onMultiOptionSelected(val, action) {
    const { activeConsult, dispatch } = this.props;
    const userId = activeConsult.user.userId;
    const requirements = [];

    val.forEach((ele) => {
      requirements.push(ele.value);
    });

    updateUserRequirements(userId, requirements)
      .then((result) => {
        console.log('the update res', result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onMultiAnswerSelected(val, action, subQuestionId, answerType) {
    if (action.action === 'select-option' || action.action === 'remove-value') {
      let answer;
      if (answerType === 'multidrop') {
        answer = val.map((ele) => ele.label).join(';;');
      } else {
        answer = JSON.stringify(val);
      }
      const { detailedQuestions, expandedRelativeId } = this.state;
      // Find the index of the selected relative
      const relativeQuestions = detailedQuestions.find(
        (ele) => ele.relativeId === expandedRelativeId,
      );
      const subQuestion = relativeQuestions.subQuestions.find(
        (ele) => ele.subQuestionId === subQuestionId,
      );
      if (answer) {
        subQuestion.answer = answer;
        subQuestion.unsaved = true;
      } else {
        subQuestion.answer = undefined;
        subQuestion.unsaved = false;
      }
      const updatedDetailedQuestions = Array.from(detailedQuestions);
      this.setState({
        detailedQuestions: updatedDetailedQuestions,
      });
    }
  }

  confirmSaveAnswers = (name) => {
    if (this.props.insurer === 'absli-vv' || this.props.insurer === 'ppmc') {
      return this.saveAnswersForRelative();
    }
    this.setState({
      showConfirmationModal: true,
    });
  };

  saveAnswersForRelative = () => {
    const { detailedQuestions, expandedRelativeId, selectedQuestionId } =
      this.state;
    const { activeConsult, insurer } = this.props;
    const relativeQuestions = detailedQuestions.find(
      (ele) => ele.relativeId === expandedRelativeId,
    );

    const answers = relativeQuestions.subQuestions.map((ele) => {
      if (ele.parentQuestionId) {
        const parentQuestionIds = ele.parentQuestionId.split(';;');
        const parentQuestionAnswers = ele.parentQuestionAnswers.split(';;');
        const parentQuestionCompareTypes = ele.compareType ? ele.compareType.split(';;') : 'eq';
        const parentQuestions = parentQuestionIds.map((id, index) => {
          const parentQuest = relativeQuestions.subQuestions.find(q => q.subQuestionId == id);
          if (parentQuest) {
            switch (parentQuestionCompareTypes[index]) {
              case 'gt':
                return parentQuest.answer > +parentQuestionAnswers[index];
              case 'le':
                return parentQuest.answer <= +parentQuestionAnswers[index];
              case 'eq':
              default:
                return parentQuest.answer == parentQuestionAnswers[index];
            }
          }
          return false;
        });
        const isVisible = parentQuestions.some(Boolean);
        return {
          subQuestionId: ele.subQuestionId,
          answerType: ele.answerType,
          isVisible: isVisible,
          answer: isVisible ? ele.answer : 'NA',
        }
      }
      return {
        subQuestionId: ele.subQuestionId,
        answerType: ele.answerType,
        answer: ele.answer,
        isVisible: true,
      };
    });

    this.setState({
      savingAnswers: true,
    });
    saveAnswersForRelative(
      activeConsult.user.userId,
      relativeQuestions.relativeId,
      selectedQuestionId,
      answers,
    )
      .then(() => this.getFormSubQuestions(selectedQuestionId))
      .then(() => {
        // if (insurer === 'aegon') {
        //   this.getFormQuestions(true);
        // }
        if (
          this.props.insurer === 'absli-vv' ||
          this.props.insurer === 'ppmc'
        ) {
          this.hideModal();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ savingAnswers: false, showConfirmationModal: false });
      });
  };

  removeAnswerForRelative = () => {
    const { expandedRelativeId, selectedQuestionId } = this.state;
    const { activeConsult } = this.props;
    this.setState({ savingAnswers: true });
    deleteAnswerForRelative(
      activeConsult.user.userId,
      expandedRelativeId,
      selectedQuestionId,
    )
      .then(() => this.getFormSubQuestions(selectedQuestionId))
      .then(() => {
        this.hideModal();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ savingAnswers: false });
      });
  };

  hideModal = () => {
    if (
      ['lic', 'aegon', 'absli', 'ipru', 'absli-vv', 'ppmc'].includes(
        this.props.user.insurer,
      ) === false
    ) {
      this.setState({
        showDetailedQuestionsModal: false,
        modalLoading: true,
        selectedQuestionId: -1,
        detailedQuestions: [],
        expandedRelativeId: -1,
        expandedRelative: null,
      });
    } else {
      this.setState({
        showDetailedQuestionsModal: false,
        modalLoading: true,
        selectedQuestionId: -1,
        detailedQuestions: [],
      });
    }

    this.getFormQuestions(true);
  };

  hideConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      confirmOpenForm: false,
    });
  };

  removeAnswerForQuestionAndRelative(questionId) {
    const { activeConsult } = this.props;
    return getFormSubQuestions(activeConsult.user.userId, questionId)
      .then((res) => {
        return Promise.all(
          res.answers.map((ele) =>
            deleteAnswerForRelative(
              activeConsult.user.userId,
              ele.relativeId,
              questionId,
            ),
          ),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onQuestionToggled = (questionId) => (e) => {
    const selected = e.target.checked;
    const { questions, questionsSelected } = this.state;
    if (selected) {
      const updatedQuestionsSelected = new Set(questionsSelected);
      updatedQuestionsSelected.add(questionId);
      this.setState({
        questionsSelected: updatedQuestionsSelected,
      });
    } else {
      // Remove the question Id from the set
      const shouldRemove = window.confirm(
        'Are you sure? All answers under this question will be deleted and not added to the report',
      );
      if (shouldRemove) {
        // Get the question
        const removeAnswers = [];
        const selectedQuestion = questions.find(
          (ele) => ele.mainQuestionId === questionId,
        );
        selectedQuestion.questions.forEach((ques) => {
          if (ques.answer === 'YES') {
            removeAnswers.push(
              this.removeAnswerForQuestionAndRelative(ques.questionId),
            );
          }
        });
        this.setState({ loading: true });
        Promise.all(removeAnswers)
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.getFormQuestions();
          });
      }
    }
  };
  openFormLink = () => {
    const { formLink } = this.state;
    const link = formLink;
    const anchor = document.createElement('a');
    anchor.setAttribute('href', link);
    anchor.setAttribute('target', '_blank');
    anchor.click();
    this.hideConfirmationModal();
  };

  openFormLinkMax = ({ user }) => {
    this.setState({
      openingForm: true,
    });
    getMemberFormUrl(user.userId)
      .then((res) => {
        const link = res.result.url;
        const anchor = document.createElement('a');
        anchor.setAttribute('href', link);
        anchor.setAttribute('target', '_blank');
        anchor.click();
        this.setState({
          maxFormLink: link,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          openingForm: false,
        });
      });
  };

  handleChangeRemark = (e) => {
    this.setState({ caseRemarks: e.target.value });
  };
  handleQcChangeRemark = (e) => {
    this.setState({
      qcRemarks: e.target.value,
    });
  };
  handleChangePolicyAppliedType = (e) => {
    this.setState({ policyAppliedType: e.target.value });
  };
  handleFormSubmittedRemarks = (e) => {
    this.setState({ formSubmittedRemarks: e.target.value });
  };
  handleFormSubmitted = (e) => {
    this.setState({ formSubmitted: e.target.checked });
  };
  handleChangeAdvisorNo = (e) => {
    this.setState({ advisorNo: e.target.value });
  };
  handleChangeFollowUp = (e) => {
    this.setState({ followUp: e.target.value });
  };
  handleChangeCaseType = (caseType) => {
    this.setState({ caseType });
  };
  handleDiscrepancyCase = (e) => {
    this.setState({ discrepancyCase: e.target.checked });
  };
  handleSubmitForm = () => {
    const { activeConsult, user } = this.props;
    const {
      caseRemarks,
      qcRemarks,
      followUp,
      policyAppliedType,
      advisorNo,
      formSubmittedRemarks,
      formSubmitted,
      qcError,
      adversityTag,
      discrepancyCase,
      discrepancyCallType,
      discrepancyCallRemark,
    } = this.state;
    let adversityRemarks = this.state.adversityRemarks
      ? String(this.state.adversityRemarks)
      : null;

    if (discrepancyCase === true) {
      if (!discrepancyCallRemark) {
        return showError({
          data: {
            errorMessage: 'Please provide Discrepancy Referral Reason',
          },
        });
      }
    }
    if (!caseRemarks || !policyAppliedType) {
      return showError({
        data: {
          errorMessage:
            'Please provide case remarks and how user applied for policy before submitting',
        },
      });
      return;
    }
    if (user.qc && !qcError) {
      return showError({
        data: {
          errorMessage: 'Please provide qc error type before submitting',
        },
      });
      return;
    }
    if (!adversityRemarks && adversityTag === 'No Adversity') {
      adversityRemarks = 'No Adversity';
      this.setState({
        adversityRemarks: 'No Adversity',
      });
    }
    if ((!adversityTag || !adversityRemarks) && !discrepancyCase) {
      return showError({
        data: {
          errorMessage:
            'Please provide adversity tag and adversity remark before submitting',
        },
      });
      return;
    }
    this.setState({
      savingAnswers: true,
    });
    const userId = activeConsult.user.userId;
    // let closed = true;
    // if (followUp) {
    //   closed = false
    // }
    const discrepancyRemark =
      discrepancyCase && discrepancyCallRemark ? discrepancyCallRemark : null;
    markFormComplete(
      userId,
      caseRemarks,
      followUp,
      policyAppliedType,
      advisorNo,
      formSubmitted,
      formSubmittedRemarks,
      adversityTag,
      adversityRemarks,
      qcRemarks,
      qcError,
      discrepancyRemark,
      user.qc,
    )
      .then(() => {
        const message = {
          messageTitle: 'Success',
          messageBody: 'Form mark completed and remark saved successfully.',
        };
        showSuccess(message);
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingAnswers: false,
        });
      });
  };
  submitConsentForm = () => {
    const { activeConsult, insurer } = this.props;
    const { submissionRemarks } = this.state;
    this.setState({
      savingAnswers: true,
    });
    return markFormComplete(activeConsult.user.userId, submissionRemarks)
      .then(() => {
        if (insurer === 'ppmc') {
          this.props.dispatch(updateConsults(activeConsult.consultationId));
          return null;
        }
        this.props.dispatch(resetActiveConsult());
        const message = {
          messageTitle: 'Success',
          messageBody: 'Form mark completed and remark saved successfully.',
        };
        showSuccess(message);
        this.fetchConsults();
        this.setState({ isPPMCIpruConsentModalOpen: false })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingAnswers: false,
        });
        this.handleCloseConsentModal()
      });
  };

  removeMedicine = () => {
    this.setState({
      medicineName: '',
      genericName: '',
    });
    // setting the value of react-select component to null
    this.medicineSelectRef.current.select.state.value = null;
    this.medicineSelectRef.current.state.defaultOptions = null;
  };
  onMedicineSelect = (option, action, subQuestionId) => {
    if (option) {
      option.label = option.label.replace('Add Medicine: ', '');
      this.setState({
        medicineName: option.value
          ? option.value
            .split(' ')
            .filter((ele) => ele.toLowerCase().indexOf("'s") < 0)
            .join(' ')
          : '',
        genericName: option.generic_name
          ? option.generic_name
            .split(' ')
            .filter((ele) => ele.toLowerCase().indexOf("'s") < 0)
            .join(' ')
          : '',
      });
      this.onAnswerSelected(option, action, subQuestionId);
    } else {
      this.setState({
        medicineName: '',
        genericName: '',
        lastMedicines: [],
      });
      this.medicineSelectRef.current.state.label = null;
      this.medicineSelectRef.current.state.value = null;
      this.medicineSelectRef.current.state.defaultOptions = null;
    }
  };

  getMedicines = (input) => {
    const token = `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjgzMDQ3MSwicGxhdGZvcm0iOiJNRVIiLCJ1c2VyVHlwZSI6InVzZXIiLCJpYXQiOjE3MjY1NTU5MDYsImV4cCI6MTc1ODA5MTkwNn0.ByscwAc2y9q-6fe7EzpnLoE0KbYsUpjioV8h3U2TwF0`;
    return fetchMedicines(token, input.toLowerCase())
      .then((result) => {
        result.forEach((medicine) => {
          medicine.label = medicine.brand_name
            ? medicine.brand_name
              .split(' ')
              .filter((ele) => ele.toLowerCase().indexOf("'s") < 0)
              .join(' ')
            : '';
          medicine.value = medicine.brand_name
            ? medicine.brand_name
              .split(' ')
              .filter((ele) => ele.toLowerCase().indexOf("'s") < 0)
              .join(' ')
            : '';
        });
        result.unshift({
          label: `Add Medicine: ${input}`,
          value: input,
        });
        this.setState({ lastMedicines: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getMedicines(input));
      }, 500);
    });
  };

  handleRadioInput = (e) => {
    this.setState({ showFormRadio: e.target.value });
  };


  addNewArmsoftCase = () => {
    const {
      phoneNo,
      applicationId,
      gender,
      name,
      merType,
      caseTypeLabel,
      countryName,
      countryCode,
      leadId,
      showFormRadio,
      caseType,
      remarks,
    } = this.state;
    const dob = moment(this.state.dob).format('YYYY-MM-DD');
    const source = merType === 'video' ? 'manual-video' : 'manual-voice';
    addNewArmsoftCase(
      phoneNo,
      applicationId,
      dob,
      gender,
      name,
      merType,
      source,
      caseTypeLabel,
      countryName,
      countryCode,
      leadId,
      showFormRadio,
      caseType,
      remarks,
    )
      .then((res) => {
        this.setState({
          phoneNo: '',
          applicationId: '',
          dob: '',
          gender: 'Male',
          name: '',
          merType: 'video',
          countryName: '',
          countryCode: '',
          leadId: '',
          caseTypeLabel: 'PB_VMER',
          showFormRadio: 'show',
          caseType: 'fresh-case',
          remarks: '',
        });
        this.fetchConsults();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addOptions = (input, dropdown, answer) => {
    return new Promise((resolve) => {
      const result = [];
      if (input) {
        result.push({
          label: `Other: ${input}`,
          value: `Other: ${input}`,
        });
      }
      if (answer) {
        result.push({
          label: answer,
          value: answer,
        });
      }
      if (input) {
        dropdown.forEach((e) => {
          if (e.toLowerCase().includes(input.toLowerCase()) === true) {
            result.push({
              label: e,
              value: e,
            });
          }
        });
      } else {
        dropdown.map((e) => {
          result.push({
            label: e,
            value: e,
          });
        });
      }

      resolve(result);
    });
  };

  handlePPMCConsentOpenModal = () => {
    const { questions } = this.state;
    const filteredQuestion = questions.filter(question => question.text.includes('CONFIDENTIAL REMARK'));
    this.setState({
      isPPMCIpruConsentModalOpen: true,
      filteredQuestion
    });
  };

  handleCloseConsentModal = () => {
    this.props.handleCloseConsentModal();
  };

  render() {
    const {
      questions,
      loading,
      showDetailedQuestionsModal,
      modalLoading,
      detailedQuestions,
      expandedRelativeId,
      caseRemarks,
      policyAppliedType,
      formSubmitted,
      formSubmittedRemarks,
      isPPMCIpruConsentModalOpen,
      filteredQuestion,
      advisorNo,
      savingAnswers,
      questionsSelected,
      expandedRelative,
      showConfirmationModal,
      qcRemarks,
      discrepancyCase,
      discrepancyCallType,
      discrepancyCallRemark,
      userAdditionalOptions,
      defaultRequirementOptions,
      showFormRadio,
    } = this.state;
    const { activeConsult, user, ipruAddCase } = this.props;
    const selectedRelativeAge = expandedRelative
      ? moment().diff(moment(expandedRelative.dob), 'years')
      : 0;
    const renderFormQuestions = (ele, idx) => {
      const isQuestionSelected =
        questionsSelected.has(ele.mainQuestionId) || ele.openByDefault;
      return (
        <React.Fragment>
          <React.Fragment>
            <QuestionContainer key={idx}>
              {idx === 0 &&
                userAdditionalOptions.length > 1 &&
                user &&
                user.admin &&
                this.props.insurer === 'aegon' ? (
                <React.Fragment>
                  <Select
                    options={userAdditionalOptions.map((ele) => ({
                      label: ele.label,
                      value: ele.condition,
                    }))}
                    isMulti
                    defaultValue={defaultRequirementOptions}
                    onChange={(val, action) => {
                      this.onMultiOptionSelected(val, action);
                    }}
                    menuPosition="fixed"
                    backspaceRemovesValue={false}
                    className="select-options"
                    classNamePrefix="react-select"
                  />
                </React.Fragment>
              ) : (
                ''
              )}

              <SubQuestionContainer>
                <Text fontSize="18px" color="#242327">
                  Q {idx + 1}. {ele.text}
                </Text>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={this.onQuestionToggled(ele.mainQuestionId)}
                    checked={isQuestionSelected}
                    id={'switch-' + ele.mainQuestionId}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={'switch-' + ele.mainQuestionId}
                  >
                    {isQuestionSelected ? 'YES' : 'NO'}
                  </label>
                </div>
              </SubQuestionContainer>
              {isQuestionSelected &&
                ele.questions.map((question, questionIdx) => (
                  <>
                    <SubQuestionContainer key={questionIdx}>
                      <Text
                        fontSize="14px"
                        key={question.questionId}
                        color="#242327"
                        style={{ textAlign: 'left' }}
                      >
                        {`${question.hideIndexNumber === 1 ? '' : `${questionIdx + 1}.`} ${question.text}`}
                        {question.required && <span className="text-danger"> *</span>}
                      </Text>
                      <SubAnswerContainer>
                        {question.answer === 'YES' && (
                          <StyledTappable
                            onTap={this.chooseAnswer(
                              question.questionId,
                              !(this.state.selectedQuestionId ===
                                question.questionId && this.state.viewAnswer),
                            )}
                          >
                            <Text fontSize="14px" color="#8852cc">
                              {this.state.selectedQuestionId ===
                                question.questionId && this.state.viewAnswer
                                ? 'Hide Answers'
                                : 'View Answers'}
                            </Text>
                          </StyledTappable>
                        )}
                        <TickBox
                          checked={question.answer === 'YES'}
                          onClick={this.chooseAnswer(question.questionId, true)}
                        />
                      </SubAnswerContainer>
                    </SubQuestionContainer>
                    {/* showing ppmc questions as an accordian */}
                    <div>
                      {this.state.selectedQuestionId === question.questionId &&
                        this.state.viewAnswer &&
                        renderPpmcQuestions(question)}
                    </div>
                  </>
                ))}
            </QuestionContainer>
          </React.Fragment>
        </React.Fragment>
      );
    };
    if (loading) {
      return <LoadingComponent />;
    }
    const renderInputType = (question) => {
      function range(start, end) {
        return new Array(end - start).fill().map((d, i) => i + start);
      }
      switch (question.answerType) {
        case 'text':
          return (
            <input
              type="text"
              className="form-control"
              id={'input-' + question.subQuestionId}
              value={question.answer || ''}
              onChange={this.setAnswer(question.subQuestionId)}
            />
          );
        case 'number':
          return (
            <input
              type="number"
              pattern="^[0-9]*$"
              className="form-control"
              id={'input-' + question.subQuestionId}
              value={question.answer || ''}
              onChange={this.setAnswer(question.subQuestionId)}
            />
          );
        case 'textbox':
          return (
            <textarea
              type="textarea"
              className="form-control"
              id={'input-' + question.subQuestionId}
              value={question.answer || ''}
              onChange={this.setAnswer(question.subQuestionId)}
              rows={4}
            />
          );

        case 'dropdown':
          return (
            <Select
              options={question.dropdownValues.split(';;').map((ele) => ({
                label: ele,
                value: ele,
              }))}
              value={{
                label: question.answer,
                value: question.answer,
              }}
              onChange={(val, action) => {
                this.onAnswerSelected(val, action, question.subQuestionId);
              }}
              menuPosition="fixed"
              backspaceRemovesValue={false}
              className="select-options"
              classNamePrefix="react-select"
            />
          );
        case 'creatable':
          return (
            <AsyncCreatableSelect
              loadOptions={(e) => {
                return this.addOptions(
                  e,
                  question.dropdownValues.split(';;'),
                  question.answer,
                );
              }}
              defaultOptions={question.dropdownValues
                .split(';;')
                .map((ele) => ({
                  label: ele,
                  value: ele,
                }))}
              value={{
                label: question.answer,
                value: question.answer,
              }}
              onChange={(val, action) => {
                this.onAnswerSelected(val, action, question.subQuestionId);
              }}
              menuPosition="fixed"
              backspaceRemovesValue={false}
              className="select-options"
              classNamePrefix="react-select"
              formatCreateLabel={(input) => ``}
            />
          );
        case 'multi':
          return (
            <Select
              options={JSON.parse(question.dropdownValues).map((ele) => ({
                label: ele.label,
                value: ele.value,
              }))}
              isMulti
              value={question.answer ? JSON.parse(question.answer) : ''}
              onChange={(val, action) => {
                this.onMultiAnswerSelected(val, action, question.subQuestionId);
              }}
              menuPosition="fixed"
              backspaceRemovesValue={false}
              className="select-options"
              classNamePrefix="react-select"
            />
          );
        case 'multidrop':
          return (
            <Select
              options={question.dropdownValues.split(';;').map((ele) => ({
                label: ele,
                value: ele,
              }))}
              isMulti
              value={
                question.answer
                  ? question.answer
                    .split(';;')
                    .map((ele) => ({ value: ele, label: ele }))
                  : ''
              }
              onChange={(val, action) => {
                this.onMultiAnswerSelected(
                  val,
                  action,
                  question.subQuestionId,
                  question.answerType,
                );
              }}
              menuPosition="fixed"
              backspaceRemovesValue={false}
              className="select-options"
              classNamePrefix="react-select"
            />
          );
        case 'year': {
          return this.state.expandedRelative ? (
            <Select
              options={range(
                parseInt(this.state.expandedRelative.dob.split('-')[0]) + 1,
                new Date().getFullYear() + 1,
              ).map((ele) => ({
                label: ele,
                value: ele,
              }))}
              value={{
                label: question.answer,
                value: question.answer,
              }}
              onChange={(val, action) => {
                this.onAnswerSelected(val, action, question.subQuestionId);
              }}
              menuPosition="fixed"
              backspaceRemovesValue={false}
              className="select-options"
              classNamePrefix="react-select"
            />
          ) : (
            ''
          );
        }
        case 'date': {
          return (
            <input
              type="date"
              className="form-control"
              id={'input-' + question.subQuestionId}
              dateFormat={'yyyy-mm-dd'}
              value={question.answer || ''}
              onChange={this.setAnswer(
                question.subQuestionId,
                question.answerType,
              )}
            />
          );
        }
        case 'time': {
          return (
            <input
              type="time"
              className="form-control"
              id={'input-' + question.subQuestionId}
              dateFormat={'HH:mm:ss'}
              value={question.answer || ''}
              onChange={this.setAnswer(
                question.subQuestionId,
                question.answerType,
              )}
            />
          );
        }
        case 'med-input': {
          return (
            <AsyncCreatableSelect
              // options={dosageOptions}
              ref={this.medicineSelectRef}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: '#d5d5d5',
                  primary25: '#f5f5f5',
                  primary: '#4F34D2',
                },
              })}
              value={{
                label: question.answer || '',
                value: question.answer || '',
              }}
              placeholder="Search Medicine"
              cacheOptions
              loadOptions={this.promiseOptions}
              onChange={(option, action) =>
                this.onMedicineSelect(option, action, question.subQuestionId)
              }
              formatCreateLabel={(input) => `${input} `}
            />
          );
        }
        case 'checkbox':
          return (
            <input
              type="checkbox"
              className="form-control"
              id={'input-' + question.subQuestionId}
              style={{ transform: 'scale(0.8)' }}
              checked={question.answer === 'true'}
              onChange={(e) => {
                const val = e.target.checked ? 'true' : 'false';
                this.setState({ checkboxValue: val }); // Update state with checkbox value
                this.onAnswerSelected({ value: val, label: val }, { action: 'select-option' }, question.subQuestionId);
              }}
            />
          );
        case 'aadhar':
          return (
            <div>
              <input
                type="text"
                className={`form-control is-invalid`}
                id={'input-' + question.subQuestionId}
                value={question.answer || ''}
                onChange={(e) => {
                  const value = e.target.value;

                  // Limit input to 4 characters
                  if (value.length <= 4) {
                    this.setAnswer(question.subQuestionId)(e);
                  }
                }}
              />
              <div className="invalid-feedback">
                Please enter only last 4 digit.
              </div>
            </div>
          );
        default:
          return false;
      }
    };
    const renderRelativeQuestions = (ques) => {
      const { proposerDetails } = activeConsult;
      console.log(ques, proposerDetails);
      return (
        <>
          <div className="col-6" key={ques.subQuestionId}>
            <div className="form-group row no-gutters">
              <label htmlFor="inputEmail3" className="col-sm-5 col-form-label">
                <Text fontSize="14px">{ques.subQuestion}</Text>
              </label>
              <div className="col-sm-7">{renderInputType(ques)}</div>
            </div>
          </div>
          {ques.verify &&
            ques.verificationKey &&
            proposerDetails[ques.verificationKey] ? (
            <div className="col-6" key={ques.subQuestionId}>
              <div className="form-group row no-gutters">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-5 col-form-label"
                >
                  <Text fontSize="14px">Proposal Form Value</Text>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id={'input-' + ques.subQuestionId + '__'}
                    value={proposerDetails[ques.verificationKey]}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      );
    };
    const renderPpmcQuestions = () => {
      if (detailedQuestions.length === 0) {
        return <div> No questions found </div>;
      }
      const { subQuestions } = detailedQuestions[0];

      return subQuestions.length ? (
        /*Render subquestion based on condition*/
        <div>
          {subQuestions.sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((ques) => {
            let isVisible = true;
            //when question has parent question
            if (ques.parentQuestionId) {
              const parentQuestionIds = ques.parentQuestionId.split(';;');
              const parentQuestionAnswers = ques.parentQuestionAnswers.split(';;');
              const parentQuestionCompareTypes = ques.compareType ? ques.compareType.split(';;') : 'eq';
              //split the parent question id, their answers, and compare type equal, greater, less than.
              const parentQuestions = parentQuestionIds.map((id, index) => {
                const parentQuest = subQuestions.find(q => q.subQuestionId == id);
                if (parentQuest) {
                  switch (parentQuestionCompareTypes[index]) {
                    case 'gt':
                      return parentQuest.answer > +parentQuestionAnswers[index];
                    case 'le':
                      return parentQuest.answer <= +parentQuestionAnswers[index];
                    case 'eq':
                    default:
                      return parentQuest.answer == parentQuestionAnswers[index];
                  }
                }
                return false;
              });
              // when condition is met set isvisible to true
              isVisible = parentQuestions.some(Boolean);
            }
            // render question only when is visible is true
            return isVisible && (
              <PpmcQuestionsContainer key={ques.subQuestionId}>
                <div className="form-group row w-100">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-5 col-form-label"
                  >
                    <Text fontSize="14px">{ques.subQuestion}</Text>
                  </label>
                  <div className="col-sm-7">{renderInputType(ques)}</div>
                </div>
              </PpmcQuestionsContainer>
            )
          })}
          <PpmcQuestionsContainer>
            <div>
              <PrimaryButton onTap={this.confirmSaveAnswers}>
                <BoldText fontSize="14px" color="#fff">
                  Save Answer
                </BoldText>
              </PrimaryButton>
            </div>
            <div className="col-6">
              <RemoveButton
                onTap={this.removeAnswerForRelative}
                loading={savingAnswers}
              >
                <Text fontSize="14px" color={'#8852cc'}>
                  Remove
                </Text>
              </RemoveButton>
            </div>
          </PpmcQuestionsContainer>
        </div>
      ) : (
        ''
      );
    };

    const renderRelatives = (ele) => {
      const questionsAnsweredForRelative = ele.subQuestions.every(
        (ans) => ans.answer !== undefined,
      );
      const isSomeQuestionsUnanswered = ele.subQuestions.some(
        (ans) => ans.answer === undefined,
      );
      const questionsUnsavedForRelative = ele.subQuestions.some(
        (ans) => ans.unsaved,
      );
      let proceed = true;
      if (this.props.insurer === 'aegon') {
        proceed = ele.subQuestions.every((ans) => !!ans.doctorId);
      }
      if (this.props.insurer === 'ppmc') {
        proceed = ele.subQuestions.every((ans) => !!ans.doctorId);
      }
      const isSelected = ele.relativeId === expandedRelativeId;
      let age = moment().diff(moment(ele.dob), 'years');
      if (ele.dobTxt) {
        age = moment().diff(moment(ele.dobTxt), 'years');
      }
      // const isDisable = (age < 18 && (selectedQuestionId === 58 || selectedQuestionId === 18)) || (gender === 'Male' && (selectedQuestionId >= 149 && selectedQuestionId <= 155)) || (gender === 'Male' && (selectedQuestionId === 66));
      const isDisable = ele.questionDisabled;
      return (
        <div
          className={`card overflow-visible mb-5 ${isSelected ? 'border-primary' : ''
            }`}
          style={
            isDisable
              ? {
                opacity: '0.3',
              }
              : {}
          }
          key={ele.relativeId}
        >
          <div className="card-header p-4" id={'relative' + ele.relativeId}>
            <h4 className="mb-0">
              <button
                className="btn btn-link btn-block text-left d-flex justify-content-between"
                type="button"
                disabled={savingAnswers || isDisable}
                onClick={this.selectRelative(ele)}
              >
                <BoldText
                  fontSize={isSelected ? '22px' : '18px'}
                  color={ele.gender === 'Male' ? 'var(--blue)' : 'var(--pink)'}
                >
                  {expandedRelativeId === ele.relativeId ? (
                    <FaCaretDown />
                  ) : (
                    <FaCaretRight />
                  )}
                  {ele.name}
                  {user.insurer !== 'lic' &&
                    `, ${ele.gender}, ${age} years old`}
                </BoldText>
                {questionsUnsavedForRelative ? (
                  <BoldText fontSize="14px" color="#FFA800">
                    Unsaved
                  </BoldText>
                ) : questionsAnsweredForRelative ? (
                  <BoldText fontSize="14px" color="#1EB865">
                    Saved
                  </BoldText>
                ) : isSelected ? (
                  <BoldText fontSize="14px" color="var(--primary)">
                    SELECTED
                  </BoldText>
                ) : (
                  <BoldText fontSize="14px" color="#F25540">
                    Not Selected
                  </BoldText>
                )}
              </button>
            </h4>
          </div>
          <div
            className={`collapse ${expandedRelativeId === ele.relativeId ? 'show' : ''
              }`}
            data-parent="#relativeAccordion"
          >
            <div className="card-body">
              <div className="container p-3">
                <div className="row">
                  <div className="col-12">
                    <BoldText
                      fontSize="14px"
                      color="#F25540"
                      className="mt-2 mb-2"
                    >
                      Please ensure to save answers for a patient before
                      switching
                    </BoldText>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <h3>
                        <strong>
                          Saving for {ele.name}, {ele.gender}
                        </strong>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {ele.subQuestions.map(renderRelativeQuestions)}
                </div>
                <div className="row">
                  <div className="col-6">
                    <PrimaryButton
                      disabled={
                        (!questionsUnsavedForRelative ||
                          isSomeQuestionsUnanswered) &&
                        proceed
                      }
                      onTap={this.confirmSaveAnswers}
                      loading={savingAnswers}
                    >
                      <BoldText fontSize="14px" color="#fff">
                        Save for {ele.name}
                      </BoldText>
                    </PrimaryButton>
                  </div>
                  <div className="col-6">
                    <RemoveButton
                      disabled={
                        !questionsAnsweredForRelative ||
                        questionsUnsavedForRelative
                      }
                      onTap={this.removeAnswerForRelative}
                      loading={savingAnswers}
                    >
                      <Text
                        fontSize="14px"
                        color={
                          questionsAnsweredForRelative &&
                            !questionsUnsavedForRelative
                            ? '#8852cc'
                            : '#A5AAB0'
                        }
                      >
                        Remove
                      </Text>
                    </RemoveButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    if (!activeConsult.consultationId) {
      if (ipruAddCase) {
        const disabled =
          !this.state.applicationId ||
          !this.state.name ||
          !this.state.phoneNo ||
          !this.state.dob ||
          !this.state.gender;
        return (
          <div style={{ padding: '32px' }}>
            <div className="row mt-1">
              <div className="col-6">
                <Text>
                  Please Fill All the below details for the application
                </Text>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Application ID</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter application id"
                  value={this.state.applicationId}
                  onChange={(e) => {
                    this.setState({
                      applicationId: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Name</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter prosper name here"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Phone number</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter prosper phone number here"
                  value={this.state.phoneNo}
                  onChange={(e) => {
                    this.setState({
                      phoneNo: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Gender</Text>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={this.state.gender}
                  onChange={(e) => {
                    this.setState({
                      gender: e.target.value,
                    });
                  }}
                >
                  <option value={'Male'}>Male</option>
                  <option value={'Female'}>Female</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Case Type</Text>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={this.state.caseTypeLabel}
                  onChange={(e) => {
                    this.setState({
                      caseTypeLabel: e.target.value,
                    });
                  }}
                >
                  <option value={'PB_VMER'}>PB VMER</option>
                  <option value={'MCA'}>MCA</option>
                  <option value={'Servicing'}>Servicing</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Mer Type</Text>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={this.state.merType}
                  onChange={(e) => {
                    this.setState({
                      merType: e.target.value,
                    });
                  }}
                >
                  <option value={'video'}>video</option>
                  <option value={'voice'}>tele</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Country Name</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter country name here"
                  value={this.state.countryName}
                  onChange={(e) => {
                    this.setState({
                      countryName: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Country Code</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter country code here"
                  value={this.state.countryCode}
                  onChange={(e) => {
                    this.setState({
                      countryCode: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Lead Id</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter lead Id here"
                  value={this.state.leadId}
                  onChange={(e) => {
                    this.setState({
                      leadId: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Dob</Text>
              </div>
              <div className="col-6">
                <DatePicker
                  selected={this.state.dob}
                  onChange={(date) => {
                    this.setState({
                      dob: date,
                    });
                  }}
                  showMonthDropdown
                  adjustDateOnChange
                  showYearDropdown
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                // className="form-control"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Show Form</Text>
              </div>
              <div className="col-6">
                <input
                  type="radio"
                  value="show"
                  name="from-option"
                  onChange={this.handleRadioInput}
                  checked={showFormRadio === 'show'}
                />{' '}
                Show &nbsp;&nbsp;
                <input
                  type="radio"
                  value="hide"
                  name="form-option"
                  onChange={this.handleRadioInput}
                  checked={showFormRadio === 'hide'}
                />{' '}
                Hide
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Case Type</Text>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={this.state.caseType}
                  onChange={(e) => {
                    this.setState({
                      caseType: e.target.value,
                    });
                  }}
                >
                  <option value={'fresh-case'}>Fresh Case</option>
                  <option value={'discrepancy-additional-info'}>
                    Discrepancy Additional-info
                  </option>
                  <option value={'discrepancy-rectification'}>
                    Discrepancy Rectification
                  </option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <Text>Remarks</Text>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter remarks here"
                  value={this.state.remarks}
                  onChange={(e) => {
                    this.setState({
                      remarks: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row form-group clearfix">
              <PrimaryButton
                onTap={this.addNewArmsoftCase}
                className="mt-2"
                disabled={disabled}
              >
                <Text fontSize="14px" color="#fff">
                  Add case
                </Text>
              </PrimaryButton>
            </div>
          </div>
        );
      }
      return (
        <OuterContainer>
          <BodyContainer>
            <BoldText fontSize="14px">
              Please select a consultation to view the form
            </BoldText>
          </BodyContainer>
        </OuterContainer>
      );
    }
    return (
      <OuterContainer>
        {
          <BodyContainer ref={this.conversationBodyRef}>
            {this.state.fetchMessages && (
              <LoadingContainer>
                <LoadingIcon className="icon-spin" />
                <Text>Fetching Messages...</Text>
              </LoadingContainer>
            )}
            {user.insurer === 'ipru' && activeConsult.source === 'ONLINE' ? (
              <div>
                <div className="row mt-1">
                  <div className="col-6">
                    <Text>Please click here to view the form</Text>
                  </div>
                  <div className="form-group clearfix">
                    <PrimaryButton
                      onTap={() => {
                        this.setState({
                          confirmOpenForm: true,
                        });
                      }}
                    >
                      <Text fontSize="14px" color="#fff">
                        Open Form
                      </Text>
                    </PrimaryButton>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label class="form-check-label" for="formSubmittedInput">
                      <Text>Form Submitted</Text>
                    </label>
                  </div>
                  <div className="col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      checked={formSubmitted}
                      onChange={this.handleFormSubmitted}
                      id="formSubmittedInput"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label class="form-check-label" for="formSubmittedInput">
                      <Text>Discrepancy Case ?</Text>
                    </label>
                  </div>
                  <div className="col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      checked={discrepancyCase}
                      onChange={this.handleDiscrepancyCase}
                      id="discrepancyCaseInput"
                    />
                  </div>
                </div>
                {discrepancyCase ? (
                  <div>
                    {/* <div className="row mt-3">
                      <div className="col-3">
                        <Text>discrepancy call type</Text>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please discrepancy call type"
                          value={discrepancyCallType}
                          onChange={(e) => {
                            this.setState({
                              discrepancyCallType: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    </div> */}
                    <div className="row mt-3">
                      <div className="col-3">
                        <Text>Discrepancy Referral Reason:</Text>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please enter your answer here"
                          value={discrepancyCallRemark}
                          onChange={(e) => {
                            this.setState({
                              discrepancyCallRemark: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="row mt-3">
                  <div className="col-3">
                    <Text>If form not submitted, add remarks</Text>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Please enter your answer here"
                      value={formSubmittedRemarks}
                      onChange={this.handleFormSubmittedRemarks}
                    ></input>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3">
                    <Text>How has user applied for policy:</Text>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Please enter your answer here"
                      value={policyAppliedType}
                      onChange={this.handleChangePolicyAppliedType}
                    ></input>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3">
                    <Text>Advisor No, if available</Text>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Advisor No"
                      value={advisorNo}
                      onChange={this.handleChangeAdvisorNo}
                    ></input>
                  </div>
                </div>

                {discrepancyCase ? (
                  ''
                ) : (
                  <>
                    <div className="row mt-3">
                      <div className="col-3">
                        <Text>Adversity Tag:</Text>
                      </div>
                      <div className="col-6">
                        <select
                          className="form-control"
                          value={this.state.adversityTag}
                          onChange={(e) => {
                            this.setState({
                              adversityTag: e.target.value,
                            });
                          }}
                        >
                          {' '}
                          <option value={''}>Please select one option</option>
                          <option value={'Single'}>Single</option>
                          <option value={'Multiple'}>Multiple</option>
                          <option value={'No Adversity'}>No Adversity</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3">
                        <Text>Adversity Remark:</Text>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your remark for adversity"
                          value={this.state.adversityRemarks}
                          onChange={(e) => {
                            this.setState({
                              adversityRemarks: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    </div>
                  </>
                )}
                {/*<div className="row mt-3">
                    <div className="col-3">
                      <Text>NRI/NON-NRI:</Text>
                    </div>
                    <div className="col-6">
                      <Select
                        value={this.state.caseType}
                        options={this.state.nriOptions}
                        onChange={this.handleChangeCaseType}
                        className="select-options"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div><div className="row mt-3">
                    <div className="col-3">
                      <Text>MARS FILLED:</Text>
                    </div>
                    <div className="col-6">
                      <Select
                        value={this.state.marsFilled}
                        options={this.state.marsFilledOptions}
                        onChange={this.handleChangeMarsFilled}
                        className="select-options"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div> */}
                {/* <div className="row mt-3">
                    <div className="col-3">
                      <Text>Follow up:</Text>
                    </div>
                    <div className="col-6">
                      <input
                          type="datetime-local"
                          className="form-control"
                          placeholder="Follow up time"
                          onChange={this.handleChangeFollowUp}
                      />
                    </div>
                  </div> */}
                <div className="row mt-3">
                  <div className="col-3">
                    <Text>Remark:</Text>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your remark"
                      value={caseRemarks}
                      onChange={this.handleChangeRemark}
                    ></input>
                  </div>
                </div>
                {user.qc || user.admin ? (
                  <>
                    <div className="row mt-3">
                      <div className="col-3">
                        <Text>QC Remark:</Text>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your remark for QC"
                          value={qcRemarks}
                          onChange={this.handleQcChangeRemark}
                        ></input>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3">
                        <Text>QC ERRORS:</Text>
                      </div>
                      <div className="col-6">
                        <select
                          className="form-control"
                          value={this.state.qcError}
                          onChange={(e) => {
                            this.setState({
                              qcError: e.target.value,
                            });
                          }}
                        >
                          {' '}
                          <option value={''}>Please select one option</option>
                          <option value={'No Error'}>No Error</option>
                          <option value={'Minor Errors'}>Minor Errors</option>
                          <option value={'Major Errors'}>Major Errors</option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div className="row form-group clearfix ml-2">
                  <PrimaryButton
                    onTap={this.handleSubmitForm}
                    className="mt-2"
                    disabled={savingAnswers}
                  >
                    <Text fontSize="14px" color="#fff">
                      Submit Form
                    </Text>
                  </PrimaryButton>
                </div>
              </div>
            ) : user.insurer === 'max' && activeConsult.showForm === false ? (
              <div className="mt-1">
                <div className="col-6">
                  <Text>Please click here to view the form</Text>
                </div>
                <br />
                <div className="mt-1 form-group clearfix row col-6">
                  <span className="ml-2"></span>
                  <PrimaryButton
                    onTap={() => this.openFormLinkMax(activeConsult)}
                    disabled={this.state.openingForm}
                  >
                    <Text fontSize="14px" color="#fff">
                      Open Form
                    </Text>
                  </PrimaryButton>
                </div>
                {this.state.maxFormLink ? (
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    <p>Link: </p>
                    <a href={this.state.maxFormLink}>
                      {this.state.maxFormLink}
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : questions.length === 0 ? (
              <Text fontSize="14px">No Questions Available</Text>
            ) : (
              <>
                {questions.length === 0 ? (
                  <Text>No questions available.</Text>
                ) : (
                  <>
                    {user.insurer === 'ipru' ? (
                      <div className="row mt-1">
                        <div className="col-6">
                          <Text>Please click here to view the form</Text>
                        </div>
                        <div className="form-group clearfix">
                          <PrimaryButton
                            onTap={() => {
                              this.setState({
                                confirmOpenForm: true,
                              });
                            }}
                          >
                            <Text fontSize="14px" color="#fff">
                              Open Form
                            </Text>
                          </PrimaryButton>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                            {questions.map(renderFormQuestions)}
                    {(isPPMCIpruConsentModalOpen || this.props.showConsentModal) && (
                      <Modal>
                        <ModalContainer>
                          <ModalBody className="overflow-auto">
                            <>
                              <CloseContainer onTap={this.handleCloseConsentModal}>
                                <CloseIcon />
                              </CloseContainer>
                              <div className="container mt-2">
                                <div className="accordion" id="relativeAccordion">
                                          {filteredQuestion.map(renderFormQuestions)}
                                </div>

                              </div>
                              <PrimaryButton
                                style={{ marginRight: '10px' }}
                                onTap={this.submitConsentForm}
                                className="mt-2"
                                loading={savingAnswers}
                                disabled={savingAnswers || this.state.questions.filter(question => question.mainQuestionId === '35').some(section =>
                                  section.questions.some(question => question.answer === '' || question.answer === 'NO'))}
                              >
                                <Text fontSize="14px" color="#fff">
                                  Submit Form
                                </Text>
                              </PrimaryButton>
                            </>
                          </ModalBody>
                        </ModalContainer>
                      </Modal>
                    )}

                    {user.insurer === 'ipru' ? (
                      <>
                        {/* <div className="row mt-3">
                          <div className="col-6">
                            <label
                              class="form-check-label"
                              for="formSubmittedInput"
                            >
                              <Text>Form Submitted</Text>
                            </label>
                          </div>
                          <div className="col-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked={formSubmitted}
                              onChange={this.handleFormSubmitted}
                              id="formSubmittedInput"
                            />
                          </div>
                        </div> */}
                        {/* <div className="row mt-3">
                          <div className="col-3">
                            <Text>If form not submitted, add remarks</Text>
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Please enter your answer here"
                              value={formSubmittedRemarks}
                              onChange={this.handleFormSubmittedRemarks}
                            ></input>
                          </div>
                        </div> */}
                        <div className="row mt-3">
                          <div className="col-6">
                            <label
                              class="form-check-label"
                              for="formSubmittedInput"
                            >
                              <Text>Discrepancy Case ?</Text>
                            </label>
                          </div>
                          <div className="col-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked={discrepancyCase}
                              onChange={this.handleDiscrepancyCase}
                              id="discrepancyCaseInput"
                            />
                          </div>
                        </div>
                        {discrepancyCase ? (
                          <div>
                            {/* <div className="row mt-3">
                              <div className="col-3">
                                <Text>discrepancy call type</Text>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Please discrepancy call type"
                                  value={discrepancyCallType}
                                  onChange={(e) => {
                                  this.setState({
                                      discrepancyCallType: e.target.value,
                                    });
                                  }}
                                ></input>
                              </div>
                            </div> */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <Text>Discrepancy Referral Reason:</Text>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Please enter your answer here"
                                  value={discrepancyCallRemark}
                                  onChange={(e) => {
                                    this.setState({
                                      discrepancyCallRemark: e.target.value,
                                    });
                                  }}
                                ></input>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="row mt-3">
                          <div className="col-3">
                            <Text>How has user applied for policy:</Text>
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Please enter your answer here"
                              value={policyAppliedType}
                              onChange={this.handleChangePolicyAppliedType}
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-3">
                            <Text>Advisor No, if available</Text>
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Advisor No"
                              value={advisorNo}
                              onChange={this.handleChangeAdvisorNo}
                            ></input>
                          </div>
                        </div>
                        {discrepancyCase ? (
                          ''
                        ) : (
                          <>
                            <div className="row mt-3">
                              <div className="col-3">
                                <Text>Adversity Tag:</Text>
                              </div>
                              <div className="col-6">
                                <select
                                  className="form-control"
                                  value={this.state.adversityTag}
                                  onChange={(e) => {
                                    this.setState({
                                      adversityTag: e.target.value,
                                    });
                                  }}
                                >
                                  {' '}
                                  <option value={''}>
                                    Please select one option
                                  </option>
                                  <option value={'Single'}>Single</option>
                                  <option value={'Multiple'}>Multiple</option>
                                  <option value={'No Adversity'}>
                                    No Adversity
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-3">
                                <Text>Adversity Remark:</Text>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your remark for adversity"
                                  value={this.state.adversityRemarks}
                                  onChange={(e) => {
                                    this.setState({
                                      adversityRemarks: e.target.value,
                                    });
                                  }}
                                ></input>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row mt-3">
                          <div className="col-3">
                            <Text>Remark:</Text>
                          </div>
                          <div className="col-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your remark"
                              value={caseRemarks}
                              onChange={this.handleChangeRemark}
                            ></input>
                          </div>
                        </div>
                        {user.qc || user.admin ? (
                          <>
                            <div className="row mt-3">
                              <div className="col-3">
                                <Text>QC Remark:</Text>
                              </div>
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your remark for QC"
                                  value={qcRemarks}
                                  onChange={this.handleQcChangeRemark}
                                ></input>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-3">
                                <Text>QC ERRORS:</Text>
                              </div>
                              <div className="col-6">
                                <select
                                  className="form-control"
                                  value={this.state.qcError}
                                  onChange={(e) => {
                                    this.setState({
                                      qcError: e.target.value,
                                    });
                                  }}
                                >
                                  {' '}
                                  <option value={''}>
                                    Please select one option
                                  </option>
                                  <option value={'No Error'}>No Error</option>
                                  <option value={'Minor Errors'}>
                                    Minor Errors
                                  </option>
                                  <option value={'Major Errors'}>
                                    Major Errors
                                  </option>
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        <div className="row form-group clearfix ml-2">
                          <PrimaryButton
                            onTap={this.handleSubmitForm}
                            className="mt-2"
                            disabled={savingAnswers}
                          >
                            <Text fontSize="14px" color="#fff">
                              Submit Form
                            </Text>
                          </PrimaryButton>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </BodyContainer>
        }
        {showDetailedQuestionsModal && (
          <Modal>
            <ModalContainer>
              <ModalBody className="overflow-auto">
                {modalLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    <CloseContainer onTap={this.hideModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <BoldText fontSize="16px" color="#242327">
                      Please select the patient and fill in the questions
                    </BoldText>
                    <div className="container mt-2">
                      <div className="row">
                        <div className="col-12">
                          <div className="accordion" id="relativeAccordion">
                            {detailedQuestions.map(renderRelatives)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {showConfirmationModal && (
          <Modal>
            <ModalContainer>
              <ModalBody className="overflow-auto">
                <>
                  <CloseContainer onTap={this.hideConfirmationModal}>
                    <CloseIcon />
                  </CloseContainer>
                  <BoldText fontSize="22px" color="#242327">
                    Are you sure you want to save answers for{' '}
                    <strong>
                      {expandedRelative.name}, {expandedRelative.gender},{' '}
                      {selectedRelativeAge} years
                    </strong>
                  </BoldText>
                  <PrimaryButton
                    className="mt-3"
                    onTap={this.saveAnswersForRelative}
                    loading={savingAnswers}
                  >
                    <Text fontSize="14px" color="#fff">
                      Yes, I am sure
                    </Text>
                  </PrimaryButton>
                </>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {this.state.confirmOpenForm && (
          <Modal>
            <ModalContainer>
              <ModalBody
                className="overflow-auto"
                style={{
                  width: '563px',
                }}
              >
                <>
                  <CloseContainer onTap={this.hideConfirmationModal}>
                    <CloseIcon />
                  </CloseContainer>
                  <BoldText fontSize="22px" color="#242327">
                    Please confirm if all the IPRU form tabs are closed.
                  </BoldText>
                  <PrimaryButton className="mt-3" onTap={this.openFormLink}>
                    <Text fontSize="14px" color="#fff">
                      Yes, I am sure
                    </Text>
                  </PrimaryButton>
                </>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  chatInfo: state.chat,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  activeFilterName: state.info.activeFilterName,
  user: state.user,
  ipruAddCase: state.consults.ipruAddCase,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(FormContainer);
