import { store } from '../store';
import config from '../config';

export function getBaseUrlForInsurer() {
  const { user } = store.getState();
  switch (user.insurer) {
    case 'abhi':
      return `${config.apiBaseUrl}/abhi`;
    case 'ipru':
      return `${config.apiBaseUrl}/icici`;
    case 'icicilbd':
      return `${config.apiBaseUrl}/icicilbd`;
    case 'lic':
      return `${config.apiBaseUrl}/lic`;
    case 'aegon':
      return `${config.apiBaseUrl}/aegon`;
    case 'reliance':
      return `${config.apiBaseUrl}/reliance`;
    case 'absli':
      return `${config.apiBaseUrl}/absli`;
    case 'absli-vv':
      return `${config.apiBaseUrl}/absli`;
    case 'tata':
      return `${config.apiBaseUrl}/tata`;
    case 'hdfc':
      return `${config.apiBaseUrl}/hdfc`;
    case 'kotak':
      return `${config.apiBaseUrl}/kotak`;
    case 'mchi':
      return `${config.apiBaseUrl}/mchi`;
    case 'ppmc':
      return `${config.apiBaseUrl}/ppmc`;
    case 'godigit':
      return `${config.apiBaseUrl}/godigit`;
    default:
      return `${config.apiBaseUrl}/max-users`;
  }
}
