import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import {
  FaCheckCircle,
  FaExchangeAlt,
  FaTimes,
  FaCalendarAlt,
  FaCircleNotch,
} from 'react-icons/fa';
import { MdBlock } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import DatePicker from 'react-datepicker';
import Modal from '../containers/common/Modal';
import DashboardHeader from '../components/DashboardHeader';
import StyledTappable from '../components/common/StyledTappable';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BlackText,
  BoldText,
  RegularText,
  Text,
} from '../components/common/Text';
import VoiceCallSuccessModal from '../components/consultations/VoiceCallSuccessModal';
import TransferConsultationBody from '../components/consultations/TransferConsultationBody';
import VideoCallSuccessModal from '../components/videoCallSuccessModal';
import AddNewCaseModal from '../components/AddNewCaseModal';
import AddNewCaseModalAegon from '../components/AddNewCaseModalAegon';
import AddNewCaseModalAbsli from '../components/AddNewCaseModalAbsli';
import {
  voiceCallUser,
  initiateFormMerCall,
  scheduleCall,
  getUserMembers,
  adminConsole,
  transferCases,
} from '../services';
import {
  callMade,
  selectConsult,
  resetActiveConsult,
  fetchConsults,
  fetchConsultsOnPagination,
  setSearchText,
  resetConsults,
  toggleFilter,
} from '../actions';

import Select from 'react-select';

import app from '../reducers/app';
import user from '../reducers/user';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`;

const OfflineBar = styled.div`
  background: #ec5d57;
  padding: 6px 0;
  color: white;
  text-align: center;
`;

const ReconnectedBar = styled(OfflineBar)`
  background: #56c02c;
`;

const OfflineText = styled(BoldText)`
  font-size: 1.8rem;
`;

const BodyContainer = styled.div`
  /* display: flex;
  flex: 1 0;
  align-items: stretch; */
  overflow-y: auto;
`;

const Cell = styled(RegularText)`
  color: 'rgba(0,0,0,0.7)';
  margin-right: 8px;
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 10px;
  border-bottom: ${(props) => (props.active ? '3px solid #8852CC' : 'none')};
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const ActionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChangeIcon = styled(FaExchangeAlt)`
  margin-right: 8px;
`;

const ScheduleIcon = styled(FaCalendarAlt)`
  margin-right: 8px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;
const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const HeaderText = styled(BlackText)`
  font-size: 2.4rem;
  margin: 14px 0;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.color ? props.color : '#8852CC')};
  margin: 0 0.25rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
`;

const LabelName = styled(Text)`
  font-size: 1.2rem;
  color: white;
`;

const SelectTable = selectTableHoc(ReactTable);

const ButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.625rem;
  align-items: center;
`;

const LoadingText = styled(Text)`
  font-size: 1.4rem;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
  transform: translate(2);
`;

class MasterAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transferLoading: false,
      showTransferModal: false,
      totalRemarkCases: [],
      selectRemarkCases: [],
      selectedTransferDoctorId: -1,
      showReconnectedBar: false,
      loading: true,
      showVoiceCallConfirmModal: false,
      showApplicationId: false,
      showVideoCallScheduleModal: false,
      showVideoCallConfirmModal: false,
      showTransferConsultationModal: false,
      showVoiceCallModal: false,
      videoCallLoading: false,
      scheduleDate: new Date(),
      consults: [],
      searchText: '',
      showAddNewCaseModal: false,
      ucid: null,
      oneConnect: true,
      relatives: [],
      doctorsList: [],
      selectedDoctorIndex: -1,
      applicationIds: [],
    };
    this.listRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.user.authToken) {
      this.props.history.push('/');
      return;
    } else {
      this.fetchDoctorList();
      this.fetchConsults();
      this.props.dispatch(toggleFilter(10));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState } = this.props;
    const prevAppState = prevProps.appState;
    if (prevAppState === 'OFFLINE' && appState === 'ONLINE') {
      this.setState(
        {
          showReconnectedBar: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showReconnectedBar: false,
            });
          }, 3000);
        },
      );
    }
    if (this.props.consults !== prevProps.consults) {
      this.setState({ consults: [...this.props.consults], loading: false });
    }
    const { canPaginate, paginateToken, fetchConsultsUrlForPagination } =
      this.props;
    const prevCanPaginate = prevProps.canPaginate;
    const prevPaginateToken = prevProps.paginateToken;
    if (
      (canPaginate !== prevCanPaginate ||
        paginateToken !== prevPaginateToken) &&
      fetchConsultsUrlForPagination
    ) {
      this.fetchConsultsForPaginationCalled = false;
    }
    if (!fetchConsultsUrlForPagination && this.listRef.current) {
      this.listRef.current.scrollTo(0, 0);
    }
  }

  fetchConsults(doctorId, applicationIds) {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [],
        doctorId: doctorId || this.state.selectedDoctorIndex,
        applicationIds: applicationIds || this.state.applicationIds,
      }),
    );
  }

  bulkTransferCases = () => {
    const { selectedTransferDoctorId, selectRemarkCases, consults } =
      this.state;
    // Find the corresponding doctorId

    const userIds = [];
    selectRemarkCases.forEach((ele) => {
      console.log('consults', consults);
      console.log('uniqueremark', selectRemarkCases);

      const consultId = consults.find((cons) => ele === cons.consultationId);
      if (consultId) {
        userIds.push(consultId.user.userId);
      }
    });
    //userIds.push(12);
    console.log('userstater', userIds);

    this.setState({
      transferLoading: true,
    });

    //console.log('index', selectedTransferDoctorId);

    transferCases(userIds, selectedTransferDoctorId)
      .then(() => {
        //console.log('here');
        this.setState({
          selectedTransferDoctorId: -1,
          selectRemarkCases: [],
          showTransferModal: false,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          transferLoading: false,
          transferModalShown: false,
        });
      });
  };

  fetchDoctorList = () => {
    adminConsole()
      .then((data) => {
        this.setState({ doctorsList: data.activeConsults });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  submitApplication = () => {
    let appIdElements = document
      .getElementById('applicationID')
      .value.split('\n')
      .filter((ele) => ele !== '');
    this.fetchConsults(null, appIdElements);
    console.log('appid', appIdElements);
    this.setState({ showApplicationId: false, applicationIds: appIdElements });
    //console.log(appIdElements);
  };

  searchConsults = (event) => {
    event && event.preventDefault();
    const { dispatch, activeFilterName, authToken } = this.props;
    const { searchText } = this.state;
    dispatch(resetConsults());
    dispatch(setSearchText(searchText));
    if (!searchText) {
      dispatch(
        fetchConsults({
          authToken,
          filters: [{ name: 'VideoCall cases', isSelected: true }],
        }),
      );
    } else {
      dispatch(fetchConsults({ authToken, searchText }));
    }
  };

  fetchConsultsOnPagination = (paginateToken) => {
    const { dispatch, authToken, fetchConsultsUrlForPagination } = this.props;
    this.fetchConsultsForPaginationCalled = true;
    dispatch(
      fetchConsultsOnPagination({
        authToken,
        paginateToken,
        fetchConsultsUrlForPagination,
        applicationIds: this.state.applicationIds,
      }),
    );
  };

  onListScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const { fetchConsultsUrlForPagination, canPaginate, paginateToken } =
      this.props;
    // console.log('onListScroll called', { scrollHeight, scrollTop, clientHeight, fetchConsultsUrlForPagination, canPaginate, paginateToken });
    if (scrollHeight - (scrollTop + clientHeight) <= 50) {
      if (
        canPaginate &&
        fetchConsultsUrlForPagination &&
        !this.fetchConsultsForPaginationCalled
      ) {
        this.fetchConsultsOnPagination(paginateToken);
      }
    }
  };

  setActiveConsult(consult) {
    const { dispatch } = this.props;
    dispatch(selectConsult(consult));
    if (
      this.props.insurer === 'abhi' &&
      consult &&
      consult.user &&
      consult.user.userId
    ) {
      this.getUserMembers(consult.user.userId);
    }
  }

  getUserMembers = (userId) => {
    getUserMembers(userId)
      .then((res) => {
        console.log(res);
        const relatives = [];
        res.relatives.forEach((e, idx) => {
          relatives.push({
            relativeId: e.relativeId,
            name: e.name,
            scheduleTime: new Date(
              new Date().getTime() + 30 * (idx + 1) * 60000,
            ),
          });
        });
        this.setState({ relatives });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      showTransferModal: false,
      showVoiceCallConfirmModal: false,
      showVoiceCallModal: false,
      showApplicationId: false,
      videoCallLoading: false,
      showVideoCallScheduleModal: false,
      showVideoCallConfirmModal: false,
      showAddNewCaseModal: false,
      relatives: [],
      applicationIds: [],
    });
    this.props.dispatch(resetActiveConsult());
    this.fetchConsults();
  };

  confirmTransfer = (doctorId) => () => {
    this.setState({
      transferModalShown: true,
      transferDoctorId: doctorId,
    });
  };

  confirmVoiceCall = (consult) => {
    this.setActiveConsult(consult);
    this.setState({ showVoiceCallConfirmModal: true, activeConsult: consult });
  };

  initiateVoiceCall = () => {
    const { dispatch, doctorType } = this.props;
    const { userId } = this.state.activeConsult.user;
    this.setState({ voiceCallLoading: true });
    if (doctorType === 'mbbs') {
      initiateFormMerCall(userId)
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          this.setState({
            showVoiceCallConfirmModal: false,
            showVoiceCallModal: true,
            ucid: result.ucid,
          });
          dispatch(callMade(true));
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          this.setState({
            showVoiceCallConfirmModal: false,
            voiceCallLoading: false,
          });
        });
    } else {
      voiceCallUser(
        this.props.authToken,
        this.props.activeConsult.consultationId,
        userId,
      )
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          this.setState({
            showVoiceCallConfirmModal: false,
            showVoiceCallModal: true,
          });
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          this.setState({
            showVoiceCallConfirmModal: false,
            voiceCallLoading: false,
          });
        });
    }
  };

  initiateVideoCallSchedule = (consult) => {
    this.setActiveConsult(consult);
    this.setState({ showVideoCallScheduleModal: true });
  };

  onScheduleDateChanged = (date) => {
    this.setState({
      scheduleDate: date,
    });
  };
  setSchedule = () => {
    this.setState({ videoCallLoading: true });
    const { user, activeConsult } = this.props;
    let { scheduleDate, oneConnect, relatives } = this.state;
    let newScheduleDate = new Date(scheduleDate);
    newScheduleDate = new Date(
      newScheduleDate.setHours(newScheduleDate.getHours() + 5),
    );
    newScheduleDate = newScheduleDate.setMinutes(
      newScheduleDate.getMinutes() + 30,
    );
    const body = {
      scheduleTime: new Date(newScheduleDate),
      scheduleBy: user.doctorId,
      consultationId: activeConsult.consultationId,
      oneConnect,
      relatives,
    };
    scheduleCall(body)
      .then((res) => {
        this.hideModal();
        this.setState({
          showVideoCallConfirmModal: true,
          videoCallLoading: false,
        });
        this.fetchConsults();
      })
      .catch((err) => {
        this.setState({ videoCallLoading: false });
      });
  };

  CustomFilterInput = ({ filter, onChange }) => {
    return (
      <form onSubmit={this.searchConsults}>
        <input
          type="text"
          className="form-control"
          placeholder="Search consultation by name"
          id="input-doctor-name"
          value={filter ? filter.value : ''}
          onChange={(ev) => {
            this.setState({ searchText: ev.target.value });
            onChange(ev.target.value);
          }}
        />
      </form>
    );
  };

  isRemarksCaseSelected = (ele) => {
    const { selectRemarkCases } = this.state;
    console.log('sk', selectRemarkCases);
    return selectRemarkCases.includes(ele);
  };

  selectCases = (key, shift, obj) => {
    const { selectRemarkCases } = this.state;
    console.log('obj', obj);

    const updatedRemarkCasesSelected = new Set(selectRemarkCases);
    if (this.isRemarksCaseSelected(obj.consultationId)) {
      updatedRemarkCasesSelected.delete(obj.consultationId);
      this.setState({
        selectRemarkCases: [...updatedRemarkCasesSelected],
      });
    } else {
      // console.log('id', obj.user.userId);
      updatedRemarkCasesSelected.add(obj.consultationId);
      this.setState({
        selectRemarkCases: [...updatedRemarkCasesSelected],
      });
    }
  };
  //all toggle
  /* toggleAllRemarksCases = () => {
    const { remarkCasesSelected, remarksCases } = this.state;
    if (remarkCasesSelected.size === remarksCases.length) {
      this.setState({
        remarkCasesSelected: new Set(),
      });
    } else {
      this.setState({
        remarkCasesSelected: new Set(
          remarksCases.map((ele) => ele.consultationId),
        ),
      });
    }
  }; */

  showBulkTransferModalApp = () => {
    this.setState({
      showTransferModal: true,
    });
  };

  onDoctorSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDoctorIndex: val.value,
      });
      this.fetchConsults(val.value);
    }
  };

  onDoctorSelectedApp = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedTransferDoctorId: val.value,
      });
      //this.fetchConsults(val.value);
    }
  };

  /*
    AddNewCase = () => {
      return (
        <ButtonComponent>
          <Button
            onTap={() => {
              this.setState({ showAddNewCaseModal: true });
            }}
            loading={false}
            style={{ margin: '0 !important' }}
          >
            <ButtonText>Add New Case + </ButtonText>
          </Button>
        </ButtonComponent>
      );
    };
  */

  TransferAddCasesApp = () => {
    return (
      <>
        <ButtonComponent>
          <Button
            className="mt-3 float-right text-center align-self-end mr-3"
            onTap={() => {
              this.setState({ showAddNewCaseModal: true });
            }}
            loading={false}
            style={{ margin: '0 !important' }}
          >
            <ButtonText fontSize="16px" color="white">
              Add New Case +{' '}
            </ButtonText>
          </Button>
        </ButtonComponent>

        <PrimaryButton
          type="button"
          className="mt-3 float-left text-center align-self-end mr-3"
          onTap={this.showBulkTransferModalApp}
        //disabled={remarkCasesSelected.size === 0}
        >
          <Text fontSize="16px" color="white">
            Transfer Selected Cases
          </Text>
        </PrimaryButton>
      </>
    );
  };

  DoctorList = () => {
    return (
      <Select
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            position: 'relative',
          }),
        }}
        placeholder={'Select doctor'}
        options={[
          {
            value: 'unassigned',
            label: 'Unassigned',
            openFilter: true,
          },
          {
            value: -1,
            label: 'All',
            openFilter: true,
          },
        ].concat(
          this.state.doctorsList.map((ele) => ({
            value: ele.doctorId,
            label: ele.doctorName,
            openFilter: true,
          })),
        )}
        onChange={this.onDoctorSelected}
        isDisabled={this.state.loading}
        classNamePrefix="react-select"
      />
    );
  };

  render() {
    const { appState, activeConsult, insurer } = this.props;

    const {
      showReconnectedBar,
      loading,
      scheduleDate,
      doctorsList,
      selectRemarkCases,
      consults,
    } = this.state;

    const transferDoctorsList = this.state.doctorsList;

    const ActionCell = ({ original }) => {
      return (
        <>
          <ActionContainer
            onTap={() => {
              this.confirmVoiceCall(original);
            }}
          >
            <ChangeIcon />
            <Cell as="span">Initiate a call</Cell>
          </ActionContainer>
          {!original.scheduleTime && (
            <ActionContainer
              onTap={() => this.initiateVideoCallSchedule(original)}
            >
              <ScheduleIcon />
              <Cell as="span">Schedule or transfer case</Cell>
            </ActionContainer>
          )}
          {original.scheduleTime && (
            <ActionContainer
              onTap={() => this.initiateVideoCallSchedule(original)}
            >
              <ScheduleIcon />
              <Cell as="span">Re-schedule or transfer case</Cell>
            </ActionContainer>
          )}
        </>
      );
    };
    const DoctorNameHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Name</TabItemText>
        </TabItemContainer>
      );
    };
    const ActionsHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Actions</TabItemText>
        </TabItemContainer>
      );
    };

    const TableCell = (props) => {
      return (
        <Cell as="span">
          {props.original.user.userName}({props.original.consultationId})
          <LabelsContainer>
            {props.original.labels.map(renderLabel)}
          </LabelsContainer>
        </Cell>
      );
    };

    const renderLabel = (ele, idx) => (
      <LabelContainer key={idx} color={ele.color}>
        <LabelName>{ele.text}</LabelName>
      </LabelContainer>
    );

    const columns = [
      {
        Header: DoctorNameHeader,
        Cell: TableCell,
        accessor: 'name',
        filterable: true,
        Filter: this.CustomFilterInput,
        style: {
          'text-align': 'center',
        },
      },
      {
        Header: <ActionsHeader />,
        filterable: insurer === 'lic',
        Filter: insurer === 'lic' ? this.DoctorList : '',
        style: {
          'text-align': 'center',
        },
      },
      {
        Header: <ActionsHeader />,
        Cell: ActionCell,
        filterable:
          insurer === 'lic' ||
          insurer === 'aegon' ||
          insurer === 'absli' ||
          insurer === 'abhi' ||
          insurer === 'absli-vv' ||
          insurer === 'reliance' ||
          insurer === 'max' ||
          insurer === 'mchi' ||
          insurer === 'ipru' ||
          insurer === 'godigit' ||
          insurer === 'tata' ||
          insurer === 'hdfc' ||
          insurer === 'kotak' ||
          insurer === 'icicilbd',
        Filter:
          insurer === 'lic' ||
              insurer === 'aegon' ||
              insurer === 'absli' ||
              insurer === 'abhi' ||
              insurer === 'absli-vv' ||
              insurer === 'reliance' ||
              insurer === 'max' ||
              insurer === 'mchi' ||
              insurer === 'ipru' ||
              insurer === 'godigit' ||
              insurer === 'tata' ||
            insurer === 'hdfc' ||
            insurer === 'kotak' ||
            insurer === 'icicilbd'
            ? this.TransferAddCasesApp
            : '',
        style: {
          'text-align': 'center',
        },
      },
    ];

    return (
      <OuterContainer>
        {appState === 'OFFLINE' && (
          <OfflineBar>
            <OfflineText>You are offline</OfflineText>
          </OfflineBar>
        )}
        {showReconnectedBar && (
          <ReconnectedBar>
            <OfflineText>Reconnecting...</OfflineText>
          </ReconnectedBar>
        )}
        <DashboardHeader history={this.props.history} />

        {this.state.showTransferModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select a doctor to transfer the consult(s) to`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <Select
                    options={transferDoctorsList.map((ele) => ({
                      value: ele.doctorId,
                      label: `${ele.doctorName}  ${ele.vendorName ? `(${ele.vendorName})` : ''
                        } ( Monthly Target : ${ele.dailyTarget === null ? 0 : `${ele.dailyTarget}`
                        } Completed Count : ${ele.completeCount} )`,
                    }))}
                    onChange={this.onDoctorSelectedApp}
                    isDisabled={this.state.transferLoading}
                  />

                  <ButtonComponent>
                    <Button
                      onTap={this.bulkTransferCases}
                      //disabled={this.state.selectedTransferDoctorId === -1}
                      loading={this.state.transferLoading}
                    >
                      <Text fontSize="16px" color="white">
                        Transfer
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}

        {this.state.showApplicationId && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>Enter Application ID</HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <textarea
                    className="form-control"
                    id="applicationID"
                    rows="5"
                  ></textarea>

                  <LabelText>
                    {`Are you sure you want to add these application ID?`}
                  </LabelText>
                  <ButtonComponent>
                    <Button onTap={this.submitApplication}>
                      <ButtonText>Confirm</ButtonText>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}

        <BodyContainer onScroll={this.onListScroll} ref={this.listRef}>
          <ButtonComponent>
            <Button
              onTap={() => {
                this.setState({ showApplicationId: true, applicationIds: [] });
              }}
              loading={false}
              style={{ margin: '0 !important' }}
            >
              <ButtonText>Enter Application IDs </ButtonText>
            </Button>
          </ButtonComponent>
          <SelectTable
            data={consults}
            keyField="consultationId"
            className="mt-3"
            loading={loading}
            columns={columns}
            selectType="checkbox"
            showPageJump={false}
            showPageSizeOptions={false}
            toggleSelection={this.selectCases}
            isSelected={this.isRemarksCaseSelected}
            manual
            // onFetchData={this.fetchPendingCases}

            style={{
              overflow: 'hidden',
            }}

          // onFetchData={this.fetchDoctorsList}
          />

          {this.props.canPaginate && consults.length >= 20 && (
            <ButtonsContainer>
              <LoadingIcon className="icon-spin" />
              <LoadingText>Loading...</LoadingText>
            </ButtonsContainer>
          )}
        </BodyContainer>

        {this.state.showVoiceCallConfirmModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>Voice Call</HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <LabelText>
                    {`Are you sure you want to initiate voice call with ${activeConsult.user.userName} ?`}
                  </LabelText>
                  <ButtonComponent>
                    <Button
                      onTap={this.initiateVoiceCall}
                      loading={this.state.voiceCallLoading}
                    >
                      <ButtonText>Confirm</ButtonText>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showVideoCallScheduleModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {' '}
                    {activeConsult.scheduleTime
                      ? `Re-schedule video call (Current schedule time: ${new Date(
                        activeConsult.scheduleTime,
                      ).toLocaleString()})`
                      : 'Schedule a video call'}{' '}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>
                <TransferConsultationBody oneConnect={this.state.oneConnect} />
                {this.props.insurer === 'abhi' && this.state.oneConnect ? (
                  <div>
                    {this.state.relatives.map((r, idx) => {
                      return (
                        <ButtonComponent>
                          <LabelText>{`${r.name}:   `}</LabelText>
                          &nbsp;
                          <DatePicker
                            selected={r.scheduleTime}
                            onChange={(date) => {
                              const relatives = [...this.state.relatives];
                              relatives[idx].scheduleTime = date;
                              this.setState({
                                relatives,
                              });
                            }}
                            showTimeSelect
                            timeFormat="hh:mm a"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm"
                            minDate={new Date()}
                          />
                        </ButtonComponent>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <LabelText>
                      {`Set a time to schedule videocall with ${activeConsult.user.userName}`}
                    </LabelText>
                    <DatePicker
                      selected={scheduleDate}
                      onChange={this.onScheduleDateChanged}
                      showTimeSelect
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd HH:mm"
                      minDate={new Date()}
                    />
                  </>
                )}
                {this.props.insurer === 'abhi' && (
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <input
                      type="checkbox"
                      id="oneconnect"
                      name="oneconnect"
                      defaultChecked={this.state.oneConnect}
                      onChange={() => {
                        this.setState({
                          oneConnect: !this.state.oneConnect,
                        });
                      }}
                    />
                    <label for="oneconnect"> One connect</label>
                  </div>
                )}
                <ButtonComponent>
                  <Button
                    onTap={this.setSchedule}
                    loading={this.state.videoCallLoading}
                  >
                    <ButtonText>Confirm</ButtonText>
                  </Button>
                </ButtonComponent>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}

        {this.state.showVoiceCallModal && (
          <VoiceCallSuccessModal
            hideModal={() => this.hideModal()}
            showRemarkOption={true}
            insurer={this.props.insurer}
            ucid={this.state.ucid}
          />
        )}
        {this.state.showVideoCallConfirmModal && (
          <VideoCallSuccessModal hideModal={this.hideModal} />
        )}
        {this.state.showAddNewCaseModal && insurer === 'lic' && (
          <AddNewCaseModal
            hideModal={this.hideModal}
            fetchConsults={this.fetchConsults}
          />
        )}
        {this.state.showAddNewCaseModal && insurer === 'aegon' && (
          <AddNewCaseModalAegon
            hideModal={this.hideModal}
            fetchConsults={this.fetchConsults}
          />
        )}
        {this.state.showAddNewCaseModal && insurer === 'absli' && (
          <AddNewCaseModalAbsli
            hideModal={this.hideModal}
            fetchConsults={this.fetchConsults}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.app.appState,
  consults: state.consults.consults,
  activeConsult: state.consults.activeConsult,
  doctorFirstName: state.user.doctorFirstName,
  doctorId: state.user.doctorId,
  doctorType: state.user.doctorType,
  authToken: state.user.authToken,
  active: state.user.active,
  admin: state.user.admin,
  insurer: state.user.insurer,
  qc: state.user.qc,
  filters: state.info.filters,
  activeFilterName: state.info.activeFilterName,
  authToken: state.user.authToken,
  canPaginate: state.info.canPaginate,
  paginateToken: state.info.paginateToken,
  fetchConsultsUrlForPagination: state.info.fetchConsultsUrlForPagination,
  doctorsList: state.info.doctorsList,
});

export default connect(mapStateToProps)(MasterAdmin);
