const config = {
  development: {
    pubnubPublishKey: 'pub-c-f6da94d4-8e01-4ba5-9c61-569bf99dd0bf',
    pubnubSubscribeKey: 'sub-c-248b24f2-4ef0-11e9-bace-daeb5080f5f6',
    // apiBaseUrl: 'https://api.samuraijack.xyz/mer',
    apiBaseUrl: 'http://localhost:5022',

    opentokApiKey: '45467122',
  },
  staging: {
    pubnubPublishKey: 'pub-c-f6da94d4-8e01-4ba5-9c61-569bf99dd0bf',
    pubnubSubscribeKey: 'sub-c-248b24f2-4ef0-11e9-bace-daeb5080f5f6',
    apiBaseUrl: 'https://mer-beta.getvisitapp.com',
    opentokApiKey: '45467122',
  },
  production: {
    apiBaseUrl: 'https://mer.getvisitapp.com',
    pubnubPublishKey: 'pub-c-689c5a54-3c1e-4fea-9895-6fc11cd2f313',
    pubnubSubscribeKey: 'sub-c-ecd5aae2-eb01-11e9-ad72-8e6732c0d56b',
    opentokApiKey: '45440642',
  },
};

export default config[process.env.NODE_ENV];
