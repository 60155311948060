import React, { useState } from 'react';
import Modal from '../../containers/common/Modal';
import styled from 'styled-components';
import StyledTappable from '../common/StyledTappable';
import { BoldText, Text } from '../../components/common/Text';
import { FaTimes } from 'react-icons/fa';

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionButton = styled.button`
  background: #8852cc;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-top: 12px;
`

function PlaytimeRemarksModal({ submitPlaytimeRemarks, hideModal }) {
    const [remarks, setRemarks] = useState('');
    return (
        <div>
            <Modal>
                <ModalContainer>
                    <ModalBody>
                        <ModalHeader>
                            <HeaderText>Playtime Remarks</HeaderText>
                            <ButtonsComponent>
                                <CloseContainer onTap={hideModal}>
                                    <CrossIcon />
                                </CloseContainer>
                            </ButtonsComponent>
                        </ModalHeader>
                        <Body>

                            <p>Playtime of the recording is less than the actual duration of recording!</p>

                            <div className="d-flex align-items-center justify-content-around">
                                <LabelText >Remarks:</LabelText>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="doctor-name-input"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </div>
                            <ActionButton className='btn btn-primary' onClick={() => submitPlaytimeRemarks(remarks)}>Submit</ActionButton>
                        </Body>

                    </ModalBody>
                </ModalContainer>
            </Modal>
        </div>
    );

}

export default PlaytimeRemarksModal;
