import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import {
  updateQcSelfAssignLimit
} from '../../services';
import { showError } from '../../utils';
import { showSuccess } from '../../utils';
import { Input } from 'antd';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  margin-left: 1rem;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 0;
  // margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  // display: flex;
  // flex: 1;
  // align-self: stretch;
  // /* border: 2mm solid rgb(170, 50, 20, 0.6); */
  // flex-direction: column;

  display: flex;
  align-items: left;
  padding: 13px;
  // justify-content: center;
  // border: 0.5mm solid black;
  width: 100%;
`;

class QCSelfAssignLimitModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      qcSelfAssignLimit: 0
    };

    this.inputRef = React.createRef();

  }

  componentDidMount() {
    // Auto-focus the input when the component mounts
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  setQcSelfAssignLimit = () => {
    this.setState({ loading: true });
    const { qcSelfAssignLimit } = this.state;
    const { authToken, activeFilterName, dispatch, doctorType, userId } =
      this.props;

    updateQcSelfAssignLimit(qcSelfAssignLimit)
      .then(() => {
        showSuccess({ messageTitle: 'Success', messageBody: `Successfully updated self assign limit for all qc doctors as ${qcSelfAssignLimit}` });
        this.props.hideModal();
      })
      .catch((err) => {
        console.log(err);
        showError(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onDoctorSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDoctorIndex: val.value,
      });
    }
  };

  render() {
    return (
      <Modal>
        <ModalContainer >
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                {`Please enter a number to set the self assign limit for all qc doctors`}
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <Input
                ref={this.inputRef}
                style={{ width: '30%', border: '0.5mm solid black;', 'auto-focus': true }}

                onChange={(event) => {
                  this.setState({ qcSelfAssignLimit: event.target.value })
                }}
              />
              <ButtonComponent>
                <Button
                  onTap={this.setQcSelfAssignLimit}
                  loading={this.state.loading}
                >
                  <ButtonText>Set Limit</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps)(QCSelfAssignLimitModal);
